* {
	box-sizing: border-box;
	-moz-user-select: none {
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none; } }

body {
	overflow-x: hidden;
	margin: 0;
	color: $black;

	font: {
		family: Exo, sans-serif;
		size: 15px;
		weight: 300; } }

.container {
	width: 100%; }

.wrapper {
	max-width: 1020px;
	width: 100%;
	margin: 0 auto;
	padding: 0 40px; }

.polygonal-bg {
	background: {
		image: url('../img/polygonal_bg.png');
		size: cover;
		position: center;
		repeat: no-repeat; } }

sup {
	font-size: 0.7em; }

@media only screen and (max-width: 1020px) {
	.wrapper {
		max-width: 320px;
		padding: 0 15px; } }
