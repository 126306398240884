/*
 * Container style
 */

.ps {
	overflow: hidden !important;
	overflow-anchor: none;
	-ms-overflow-style: none;
	touch-action: auto;
	-ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */

.ps__rail-x {
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	height: 15px; /* there must be 'bottom' or 'top' for ps__rail-x */
	bottom: 0px; /* please don't change 'position' */
	position: absolute;
}

.ps__rail-y {
	display: none;
	opacity: 0;
	transition: background-color .2s linear, opacity .2s linear;
	-webkit-transition: background-color .2s linear, opacity .2s linear;
	width: 15px; /* there must be 'right' or 'left' for ps__rail-y */
	right: 0; /* please don't change 'position' */
	position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
	display: block;
	background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
	opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
	background-color: #eee;
	opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */

.ps__thumb-x {
	background-color: #aaa;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	transition: background-color .2s linear, height .2s ease-in-out;
	-webkit-transition: background-color .2s linear, height .2s ease-in-out;
	height: 6px; /* there must be 'bottom' for ps__thumb-x */
	bottom: 2px; /* please don't change 'position' */
	position: absolute;
}

.ps__thumb-y {
	background-color: #aaa;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	transition: background-color .2s linear, width .2s ease-in-out;
	-webkit-transition: background-color .2s linear, width .2s ease-in-out;
	width: 6px; /* there must be 'right' for ps__thumb-y */
	right: 2px; /* please don't change 'position' */
	position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
	background-color: #999;
	height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
	background-color: #999;
	width: 11px;
}

/* MS supports */

/*== Magistral ==*/

@font-face {
	font-family: 'Magistral';
	src: url("../fonts/magistral/Magistral.ttf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Magistral';
	src: url("../fonts/magistral/Magistral-Bold.ttf");
	font-weight: 700;
	font-style: normal;
}

/*== Exo ==*/

@font-face {
	font-family: 'Exo';
	src: url("../fonts/exo/Exo2.0.otf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Exo';
	src: url("../fonts/exo/Exo2.0-SemiBold.otf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Exo';
	src: url("../fonts/exo/Exo2.0-SemiBoldItalic.otf");
	font-weight: 500;
	font-style: italic;
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-moz-user-select: none;
	-moz-user-select--webkit-user-select: none;
	-moz-user-select--ms-user-select: none;
	-moz-user-select--o-user-select: none;
	-moz-user-select-user-select: none;
}

body {
	overflow-x: hidden;
	margin: 0;
	color: #212121;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 300;
}

.container {
	width: 100%;
}

.wrapper {
	max-width: 1020px;
	width: 100%;
	margin: 0 auto;
	padding: 0 40px;
}

.polygonal-bg {
	background-image: url("../img/polygonal_bg.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

sup {
	font-size: 0.7em;
}

.preloader {
	z-index: 99999;
	position: fixed;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.preloader__logo svg {
	width: 561px;
	height: 174px;
}

.preloader .logo-additionals > *:nth-child(3n) {
	-webkit-animation: flickerAnimation 1.5s linear 0s infinite;
	animation: flickerAnimation 1.5s linear 0s infinite;
}

.preloader .logo-additionals > *:nth-child(3n+1) {
	-webkit-animation: flickerAnimation 1.5s linear .7s infinite;
	animation: flickerAnimation 1.5s linear .7s infinite;
}

.preloader .logo-additionals > *:nth-child(3n+2) {
	-webkit-animation: flickerAnimation 1.5s linear .4s infinite;
	animation: flickerAnimation 1.5s linear .4s infinite;
}

.header {
	position: fixed;
	z-index: 9999;
	padding: 30px 0;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header--white {
	padding: 5px 0;
	background-color: #fff;
	-webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
}

.header--white .header__hamburger {
	background-color: transparent;
}

.header--white .header__hamburger-item {
	background-color: #0e6abf;
}

.header--alt:not(.header--white) .header__hamburger-item {
	background-color: #fff;
}

.header--alt:not(.header--white) .header__logo {
	background-image: url("../img/logo-white.svg");
}

.header--alt:not(.header--white) .header__link {
	color: #fff;
}

.header--alt:not(.header--white) .header__link:hover {
	color: #fff;
}

.header--alt:not(.header--white) .header__lang-item {
	color: #fff;
}

.header--alt:not(.header--white) .header__lang-item:not(:last-child) {
	border-right-color: #fff;
}

.header--opened.header--white {
	background-color: #0070B8;
}

.header--opened.header--white .header__logo {
	background-image: url("../img/logo-white.svg");
}

.header--opened .header__lang {
	opacity: 0;
	visibility: hidden;
}

.header__inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 1920px;
}

.header__logo {
	width: 180px;
	height: 55px;
	background-image: url("../img/logo.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.header__link {
	position: relative;
	margin: 0 15px;
	padding: 6px 0;
	color: #0E6ABF;
	text-decoration: none;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header__link:hover {
	color: #3692FE;
}

.header__link:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 1px;
	background-color: #0E6ABF;
	-webkit-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.header__link--active {
	pointer-events: none;
}

.header__link--active:after {
	width: 100%;
}

.header__lang {
	text-transform: uppercase;
}

.header__lang--mobile {
	display: none;
}

.header__lang-item {
	padding: 0 5px;
	color: #0E6ABF;
	text-decoration: none;
	opacity: .5;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header__lang-item:hover {
	opacity: 1;
	color: #3692FE;
}

.header__lang-item:not(:last-child) {
	border-right: 1px solid #0E6ABF;
}

.header__lang-item--active {
	pointer-events: none;
	opacity: 1;
}

.header__actions {
	position: relative;
	z-index: 99999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header__hamburger {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 40px;
	height: 20px;
	margin-right: 35px;
	cursor: pointer;
}

.header__hamburger--active {
	height: 30px;
}

.header__hamburger--active .header__hamburger-item {
	background-color: #fff;
}

.header__hamburger--active .header__hamburger-item:first-child {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.header__hamburger--active .header__hamburger-item:nth-child(2) {
	opacity: 0;
}

.header__hamburger--active .header__hamburger-item:last-child {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.header__hamburger-item {
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;
	width: 40px;
	height: 2px;
	background-color: #0e6abf;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header__list {
	position: absolute;
	top: 0;
	right: -390px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 390px;
	height: 100vh;
	padding: 115px 20px 30px 80px;
	background-color: #0070B8;
	-webkit-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.header__list--opened {
	right: 0;
}

.header__list-arrow {
	position: absolute;
	top: 12px;
	right: -20px;
	width: 10px;
	height: 5px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	background-image: url("../img/icons/arrow-white.svg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.header__list-arrow--active {
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}

.header__list-item {
	position: relative;
	margin-bottom: 20px;
	color: #fff;
	text-decoration: none;
	line-height: 26px;
	font-size: 18px;
}

.header__list-dropdown {
	margin-bottom: 20px;
}

.header__list-links {
	display: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding-top: 10px;
	padding-bottom: 10px;
}

.header__list-links-item {
	display: block;
	margin: 10px 0;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 300;
}

.header__out {
	margin-top: auto;
	color: #fff;
	opacity: 0.25;
	text-decoration: none;
	letter-spacing: 1px;
	font-family: 'Magistral';
	font-size: 14px;
	font-weight: 700;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.header__out:hover {
	opacity: 1;
}

.hero {
	z-index: 1;
	position: relative;
	overflow: hidden;
	height: 100vh;
}

.hero__inner {
	position: relative;
	z-index: 3;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	height: 100%;
}

.hero__inner--hydrogen,
.hero__inner--hythane {
	position: static;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero__inner--hydrogen .hero__heading,
.hero__inner--hythane .hero__heading {
	line-height: 1.4em;
}

.hero__inner--hydrogen .hero__gradient,
.hero__inner--hythane .hero__gradient {
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.hero__inner--hydrogen .hero__gradient {
	opacity: 0.5;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#0E6ABF), to(#228CC9));
	background-image: linear-gradient(180deg, #0E6ABF 0%, #228CC9 100%);
}

.hero__inner--hythane .hero__gradient {
	opacity: 1;
	background-image: url("../img/hythane-hero-bg.jpg");
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
}

.hero__hydrogen {
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	width: 327px;
	height: 419px;
	background-image: url("../img/hydrogen-big.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.hero__methane {
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(50%, -50%);
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	width: 447px;
	height: 553px;
	background-image: url("../img/methane-big.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.hero__video {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hero__molecule {
	position: absolute;
	z-index: 2;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.hero__molecule--big {
	background-image: url("../img/water-2.png");
	-webkit-animation-duration: 10s;
	animation-duration: 10s;
}

.hero__molecule--small {
	-webkit-animation-duration: 8s;
	animation-duration: 8s;
	background-image: url("../img/water-1.png");
}

.hero__molecule--1 {
	bottom: 90px;
	right: 0;
	width: 280px;
	height: 350px;
	-webkit-animation-name: molecule-big-1;
	animation-name: molecule-big-1;
}

.hero__molecule--2 {
	bottom: 0px;
	left: -135px;
	width: 180px;
	height: 220px;
	-webkit-transform: rotate(-36deg);
	-ms-transform: rotate(-36deg);
	transform: rotate(-36deg);
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
	-webkit-animation-name: molecule-big-2;
	animation-name: molecule-big-2;
}

.hero__molecule--3 {
	top: 15%;
	left: -150px;
	width: 230px;
	height: 170px;
	-webkit-animation-name: molecule-small-1;
	animation-name: molecule-small-1;
}

.hero__molecule--4 {
	top: 46%;
	right: 47.92%;
	width: 130px;
	height: 100px;
	-webkit-transform: rotate(-67deg);
	-ms-transform: rotate(-67deg);
	transform: rotate(-67deg);
	-webkit-animation-delay: 2.5s;
	animation-delay: 2.5s;
	-webkit-animation-name: molecule-small-2;
	animation-name: molecule-small-2;
}

.hero__molecule--5 {
	top: 6.5%;
	left: 41.6%;
	opacity: 0.3;
	width: 175px;
	height: 135px;
	-webkit-transform: rotate(11deg);
	-ms-transform: rotate(11deg);
	transform: rotate(11deg);
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
	-webkit-animation-name: molecule-small-3;
	animation-name: molecule-small-3;
}

.hero__molecule--6 {
	bottom: 32%;
	right: 41.6%;
	opacity: 0.75;
	width: 145px;
	height: 112px;
	-webkit-transform: rotate(-105deg);
	-ms-transform: rotate(-105deg);
	transform: rotate(-105deg);
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
	-webkit-animation-name: molecule-small-4;
	animation-name: molecule-small-4;
}

.hero__molecule--7 {
	bottom: 20%;
	left: 18.5%;
	opacity: 0.3;
	width: 110px;
	height: 85px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-animation-name: molecule-small-5;
	animation-name: molecule-small-5;
}

.hero__molecule--8 {
	top: 19%;
	left: 14%;
	opacity: 0.3;
	width: 92px;
	height: 70px;
	-webkit-transform: rotate(-24deg);
	-ms-transform: rotate(-24deg);
	transform: rotate(-24deg);
	-webkit-animation-name: molecule-small-6;
	animation-name: molecule-small-6;
}

.hero__molecule--9 {
	bottom: 38%;
	left: 6%;
	opacity: 0.3;
	width: 67px;
	height: 51px;
	-webkit-transform: rotate(15deg);
	-ms-transform: rotate(15deg);
	transform: rotate(15deg);
	-webkit-animation-name: molecule-small-7;
	animation-name: molecule-small-7;
}

.hero__block {
	position: relative;
	z-index: 3;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 700px;
	height: 325px;
	margin-bottom: 15%;
	padding: 45px;
}

.hero__bubble {
	position: absolute;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-image: linear-gradient(253.26deg, #0E6ABF 1.41%, #25A2D2 91.27%);
}

.hero__bubble--1 {
	top: -20px;
	right: -45px;
	width: 34px;
	height: 34px;
}

.hero__bubble--2 {
	top: 16px;
	right: -68px;
	width: 10px;
	height: 10px;
}

.hero__bubble--3 {
	top: 32px;
	right: -90px;
	width: 18px;
	height: 18px;
}

.hero__bubble--4 {
	top: 95px;
	right: -156px;
	width: 14px;
	height: 14px;
}

.hero__gradient {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	opacity: .75;
	background-image: linear-gradient(238.81deg, #0E6ABF 1.41%, #25A2D2 91.27%);
}

.hero__heading {
	position: relative;
	z-index: 2;
	margin: 0;
	color: #fff;
	line-height: 71px;
	text-transform: uppercase;
	font-family: Magistral, sans-serif;
	font-size: 40px;
}

.hero .scroll-cta {
	z-index: 2;
	position: absolute;
	bottom: 50px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.hero .scroll-cta--white .scroll-cta__text {
	color: #fff;
}

.hero .scroll-cta--white .scroll-cta__icon {
	background-image: url("../img/icons/arrow-circle-white.svg");
}

.hero .scroll-cta__text {
	color: #0E6ABF;
	text-transform: uppercase;
	line-height: 17px;
	font-size: 12px;
}

.hero .scroll-cta__icon {
	width: 30px;
	height: 30px;
	margin: 0 auto;
	background-image: url("../img/icons/arrow-circle.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.about {
	z-index: 2;
	position: relative;
	margin-bottom: 55px;
	padding-top: 145px;
}

.about__blot {
	position: absolute;
	z-index: 1;
	top: -33px;
	left: 0px;
	width: 400px;
	height: 680px;
	background-image: url("../img/blot-1.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.about__inner {
	position: relative;
	z-index: 2;
	max-width: 1340px;
	width: 100%;
	padding: 0;
}

.about__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	max-width: 1190px;
	padding-right: 40px;
}

.about__image {
	width: 500px;
	margin-top: 70px;
}

.about__content {
	margin-left: 35px;
}

.about__text {
	margin: 0 0 10px 0;
	line-height: 26px;
	color: #06346F;
	font-size: 17px;
}

.about__heading {
	margin: 20px 0 15px 0;
	color: #06346F;
	font-size: 17px;
}

.about__list {
	margin: 0;
	padding: 0;
}

.about__list-item {
	position: relative;
	display: block;
	margin-bottom: 15px;
	padding-left: 30px;
	line-height: 1.4em;
	font-size: 17px;
	color: #06346F;
}

.about__list-item a {
	color: #06346F;
}

.about__list-item:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	background-image: url("../img/icons/check.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.about__list-item--calc {
	cursor: pointer;
	text-decoration: underline;
	text-decoration-skip-ink: none;
}

.heading {
	width: 100%;
	margin: 0 auto 15px auto;
}

.heading__inner {
	max-width: 750px;
	width: 100%;
	margin: 0;
	line-height: 49px;
	color: #0E6ABF;
	font-family: Magistral, sans-serif;
	font-size: 36px;
}

.specs {
	position: relative;
	padding-top: 55px;
	padding-bottom: 70px;
}

.specs__blot {
	position: absolute;
	z-index: 1;
	bottom: 0px;
	right: 0px;
	width: 672px;
	height: 644px;
	-webkit-transform: translateX(215px);
	-ms-transform: translateX(215px);
	transform: translateX(215px);
	background-image: url("../img/blot-2.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.specs__table {
	position: relative;
	z-index: 2;
	max-width: 1080px;
	width: 100%;
	margin: 0 auto;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.specs__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: stretch;
	-webkit-justify-content: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.specs__row:nth-child(even) {
	background-color: #fff;
}

.specs__row:nth-child(even) .specs__cell:not(:last-child) {
	border-right: 2px solid #efefef;
}

.specs__row:nth-child(odd) {
	background-color: #efefef;
}

.specs__row:nth-child(odd) .specs__cell:not(:last-child) {
	border-right: 2px solid #fff;
}

.specs__cell {
	width: 120px;
	margin: 0;
	padding: 10px;
	color: #06346F;
	font-size: 14px;
}

.specs__cell--heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 220px;
	margin: 0;
	padding: 10px;
	color: #0E6ABF;
	text-transform: uppercase;
	line-height: 1.4em;
}

.specs__cell--heading p {
	margin: 0;
}

.specs__cell--heading sub {
	margin-bottom: -8px;
	line-height: 1em;
}

.specs__cell--heading span {
	text-transform: lowercase;
}

.specs__hint {
	position: relative;
	z-index: 2;
	margin-top: 35px 0 0 0;
	opacity: 0.8;
}

.usage {
	overflow: hidden;
	padding: 65px 0 50px 0;
	background-image: url("../img/usage_bg.png"), -webkit-gradient(linear, right top, left top, from(#1092C5), to(#1178D8));
	background-image: url("../img/usage_bg.png"), linear-gradient(-90deg, #1092C5 0%, #1178D8 100%);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.usage__inner {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.usage__molecule {
	position: absolute;
	-webkit-animation-name: molecule;
	animation-name: molecule;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-duration: 10s;
	animation-duration: 10s;
	background-image: url("../img/water-1.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.usage__molecule--1 {
	bottom: -10px;
	left: -190px;
	width: 300px;
	height: 225px;
	-webkit-animation-duration: 10s;
	animation-duration: 10s;
}

.usage__molecule--2 {
	top: 15px;
	left: -webkit-calc(50% - 200px);
	left: calc(50% - 200px);
	width: 320px;
	height: 240px;
	-webkit-animation-duration: 8s;
	animation-duration: 8s;
}

.usage__molecule--3 {
	top: -30px;
	right: -300px;
	width: 450px;
	height: 350px;
	-webkit-animation-duration: 14s;
	animation-duration: 14s;
}

.usage__heading {
	position: relative;
	width: 280px;
	color: #fff;
	text-transform: uppercase;
	line-height: 54px;
	font-family: Magistral, sans-serif;
	font-size: 36px;
}

.usage__heading:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 10px;
	right: -40px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 15px 0 15px 30px;
	border-color: transparent transparent transparent #27B8F2;
}

.usage__grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 580px;
}

.usage__col {
	width: 275px;
}

.usage__round {
	position: relative;
	z-index: 2;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 75px;
	height: 75px;
	margin: 0 auto -15px auto;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-image: -webkit-gradient(linear, left top, right top, from(#3692FE), to(#61DFF1));
	background-image: linear-gradient(90deg, #3692FE 0%, #61DFF1 100%);
}

.usage__item {
	position: relative;
	display: block;
	margin-bottom: 15px;
	text-decoration: none;
}

.usage__item:hover .usage__block:after {
	opacity: 1;
}

.usage__block {
	position: relative;
	overflow: hidden;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 275px;
	height: 115px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.usage__block:after {
	content: '';
	display: block;
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: -webkit-calc(50% - 75px);
	left: calc(50% - 75px);
	width: 150px;
	height: 3px;
	opacity: 0;
	background-color: #fff;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.usage__block-bg {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: .7;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background-image: -webkit-gradient(linear, left top, right top, from(#3692FE), to(#61DFF1));
	background-image: linear-gradient(90deg, #3692FE 0%, #61DFF1 100%);
}

.usage__block-text {
	position: relative;
	z-index: 2;
	margin: 0;
	padding: 0 10px;
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	font-size: 14px;
}

.usage__block-pulse {
	z-index: 2;
	position: absolute;
	bottom: 7px;
	left: 50%;
	width: 20px;
	height: 20px;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	opacity: 0.6;
	cursor: pointer;
}

.usage__block-pulse-item {
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	background-color: #fff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.usage__block-pulse-item:nth-child(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.usage__block-pulse-item:nth-child(2) {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.usage__block-pulse-item:nth-child(3) {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}

.fuel .container:not(.scheme-fuel) {
	padding-top: 45px;
	padding-bottom: 75px;
	background-image: url("../img/fuel_bg.png"), linear-gradient(0deg, #F0FBFF, #F0FBFF), linear-gradient(2.75deg, rgba(12, 165, 226, 0.8) 3.45%, #0E6ABF 182.08%);
	background-position: bottom right;
	background-repeat: no-repeat;
}

.fuel .scheme-fuel .wrapper {
	width: 100%;
	position: relative;
	max-width: 1780px;
	overflow-y: hidden !important;
}

.fuel .scheme-fuel .wrapper .scheme {
	width: 1700px;
}

.fuel__inner {
	max-width: 1080px;
	width: 100%;
	margin: 0 auto;
}

.fuel__desc {
	width: 550px;
	margin: 0 0 35px 0;
	color: #06346F;
	font-size: 18px;
}

.fuel__calc {
	margin-top: 0;
	margin-bottom: 20px;
	text-transform: uppercase;
	color: #06346F;
	font-size: 20px;
	font-weight: 500;
}

.fuel__calc span {
	text-transform: lowercase;
}

.fuel__scroll {
	z-index: 2;
	position: absolute;
	top: -webkit-calc(50% - 50px);
	top: calc(50% - 50px);
	right: 0;
	width: 100px;
	height: 100px;
	opacity: 0.95;
	background-image: -webkit-gradient(linear, right top, left top, from(#D5F2FF), to(#BBE4F7));
	background-image: linear-gradient(270deg, #D5F2FF 0%, #BBE4F7 100%);
}

.fuel__scroll:before,
.fuel__scroll:after {
	content: '';
	position: absolute;
	top: -webkit-calc(50% - 23px);
	top: calc(50% - 23px);
	width: 20px;
	height: 46px;
	background-image: url("../img/icons/icon-arrow.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.fuel__scroll:after {
	right: 32px;
}

.fuel__scroll:before {
	right: 45px;
}

.fuel .ps__rail-y {
	display: none;
}

.calculator {
	width: 640px;
	overflow: hidden;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.calculator__eval {
	padding: 20px 30px 25px 25px;
	background-color: #fff;
}

.calculator__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
}

.calculator__text {
	margin: 0;
	color: #06346F;
	font-size: 21px;
}

.calculator__label {
	overflow: hidden;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 245px;
	border: 1px solid #AFC4D5;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.calculator__label-input {
	-moz-appearance: textfield;
	width: 195px;
	padding: 9px 15px;
	outline: none;
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	font-family: Exo, sans-serif;
	font-size: 18px;
}

.calculator__label-input::-webkit-outer-spin-button,
.calculator__label-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.calculator__label-text {
	width: 50px;
	margin: 0;
	padding: 10px 0;
	color: #06346F;
	text-align: center;
	border-left: 1px solid #AFC4D5;
	background-color: #EEF2F5;
}

.calculator__btn {
	display: block;
	width: 225px;
	margin-top: 35px;
	padding: 12px 0 15px 0;
	text-align: center;
	color: #fff;
	text-decoration: none;
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
	background-image: -webkit-gradient(linear, left top, right top, from(#3692FE), color-stop(50%, #24CCE3), to(#3692FE));
	background-image: linear-gradient(90deg, #3692FE 0%, #24CCE3 50%, #3692FE 100%);
	background-size: 450px 1px;
	font-size: 20px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.calculator__btn:hover {
	background-position: 225px;
}

.calculator__sum {
	padding: 20px 40px;
	background-color: #f7f7f7;
	color: #06346F;
}

.calculator__sum-heading {
	margin: 0 0 5px 0;
	font-size: 18px;
}

.calculator__sum-result {
	margin: 0;
	font-size: 36px;
}

.electricity .container:not(.scheme-electricity) {
	padding-top: 50px;
	padding-bottom: 35px;
	background-color: #F0FBFF;
}

.electricity .scheme-electricity .wrapper {
	max-width: 2109px;
	width: 100%;
	position: relative;
	overflow-y: hidden !important;
}

.electricity__content {
	width: 1080px;
	margin: 0 auto;
}

.electricity__desc {
	margin-bottom: 15px;
	color: #06346F;
	font-size: 18px;
}

.electricity__list {
	width: 650px;
	margin: 0 0 30px 0;
	padding: 0;
}

.electricity__list-item {
	position: relative;
	display: block;
	margin-bottom: 15px;
	padding-left: 30px;
	color: #06346F;
	line-height: 21px;
}

.electricity__list-item:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	background-image: url("../img/icons/check.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.electricity__table {
	position: relative;
	z-index: 2;
	width: 100%;
	margin: 0 auto;
	-webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.electricity__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: stretch;
	-webkit-justify-content: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.electricity__row:nth-child(even) {
	background-color: #efefef;
}

.electricity__row:nth-child(even) .electricity__cell:not(:last-child) {
	border-right: 2px solid #fff;
}

.electricity__row:nth-child(odd) {
	background-color: #fff;
}

.electricity__row:nth-child(odd) .electricity__cell:not(:last-child) {
	border-right: 2px solid #efefef;
}

.electricity__row .electricity__cell {
	-webkit-flex-shrink: 1;
	-ms-flex-negative: 1;
	flex-shrink: 1;
	width: 185px;
	color: #06346F;
}

.electricity__row .electricity__cell:not(:first-child) {
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.electricity__cell {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0;
	padding: 15px 5px;
	color: #031940;
	font-size: 14px;
}

.electricity__cell--empty {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 18px;
}

.electricity__cell--heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 190px;
	margin: 0;
	color: #0E6ABF;
	text-transform: uppercase;
	line-height: 23px;
	font-size: 14px;
}

.electricity__cell--heading span {
	text-transform: lowercase;
}

.electricity__cell--heading sub {
	margin-top: 10px;
	font-size: 12px;
}

.electricity__hint {
	margin-top: 35px 0 0 0;
	opacity: 0.8;
}

.electricity__scroll {
	z-index: 2;
	position: absolute;
	top: -webkit-calc(50% - 50px);
	top: calc(50% - 50px);
	right: 0;
	width: 100px;
	height: 100px;
	opacity: 0.95;
	background-image: -webkit-gradient(linear, right top, left top, from(#D5F2FF), to(#BBE4F7));
	background-image: linear-gradient(270deg, #D5F2FF 0%, #BBE4F7 100%);
}

.electricity__scroll:before,
.electricity__scroll:after {
	content: '';
	position: absolute;
	top: -webkit-calc(50% - 23px);
	top: calc(50% - 23px);
	width: 20px;
	height: 46px;
	background-image: url("../img/icons/icon-arrow.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.electricity__scroll:after {
	right: 32px;
}

.electricity__scroll:before {
	right: 45px;
}

.electricity .ps__rail-y {
	display: none;
}

.certificates {
	display: none;
	padding: 50px 0 40px 0;
	background-color: #F0FBFF;
}

.certificates__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.certificates__item {
	position: relative;
	width: 220px;
	height: 310px;
	margin-bottom: 15px;
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.certificates__item:hover .certificates__bg {
	opacity: 0.35;
}

.certificates__item:hover .certificates__btn {
	opacity: 1;
}

.certificates__bg {
	z-index: 1;
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #212121;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.certificates__btn {
	z-index: 2;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 135px;
	padding: 10px;
	text-align: center;
	color: #fff;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-decoration: none;
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
	background-image: -webkit-gradient(linear, left top, right top, from(#3692FE), color-stop(50%, #24CCE3), to(#3692FE));
	background-image: linear-gradient(90deg, #3692FE 0%, #24CCE3 50%, #3692FE 100%);
	background-size: 450px 1px;
	font-size: 16px;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.certificates__btn:hover {
	background-position: 225px;
}

.redirect {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.redirect__col {
	position: relative;
	width: 50%;
}

.redirect__col:first-child .redirect__content {
	margin-left: auto;
}

.redirect__content {
	z-index: 2;
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	height: 100%;
	padding: 145px 250px 120px 70px;
	max-width: 720px;
	color: #fff;
}

.redirect__heading {
	margin: 0 0 15px 0;
	text-transform: uppercase;
	font-size: 30px;
	font-family: 'Magistral';
}

.redirect__desc {
	margin: 0 0 20px 0;
	line-height: 29px;
	font-size: 16px;
}

.redirect__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #0E6ABF;
}

.redirect__bg-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.7;
	-webkit-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	background-size: cover;
	background-position: top right;
	background-repeat: no-repeat;
}

.redirect__molecule {
	position: absolute;
	bottom: 60px;
	right: 20px;
}

.redirect__molecule--hydrogen {
	width: 222px;
	height: 284px;
	background-image: url("../img/hydrogen.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.redirect__molecule--methane {
	width: 249px;
	height: 308px;
	background-image: url("../img/methane.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.redirect__btn {
	display: block;
	width: 210px;
	padding: 15px;
	border: 2px solid #fff;
	color: #fff;
	text-align: center;
	text-decoration: none;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	font-size: 18px;
}

.redirect__btn:hover {
	background-color: #fff;
	color: #0E6ABF;
}

.footer {
	position: relative;
	height: 500px;
}

.footer--mobile {
	display: none;
}

.footer--inner {
	height: auto;
	padding: 35px 0;
	background-color: #436C9A;
}

.footer--inner .footer__wrapper {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding: 0 260px 0 100px;
}

.footer--inner .footer__grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.footer--inner .footer__col {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 25%;
	height: 75px;
}

.footer--inner .footer__col:not(:last-child):after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 1px;
	height: 100%;
	background-color: #fff;
	opacity: 0.25;
}

.footer--inner .footer__col:not(:first-child) {
	padding-left: 20px;
}

.footer--inner .footer__text {
	margin: 5px 0;
	color: #fff;
	font-size: 14px;
	font-weight: 100;
}

.footer--inner .footer__copyright {
	margin: 0;
	opacity: 0.75;
	color: #fff;
	font-size: 14px;
	font-weight: 300;
}

.footer--inner .footer__item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer--inner .footer__contacts-icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.footer__map {
	max-height: 500px;
	height: 500px;
	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	filter: gray;
}

.footer__map [class$='controls-pane'],
.footer__map [class$='copyrights-pane'] {
	display: none !important;
}

.footer__contacts {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	top: 0;
	left: 0;
	width: -webkit-calc(50% - 125px);
	width: calc(50% - 125px);
	height: 500px;
	background-color: rgba(67, 108, 154, 0.9);
	color: #fff;
}

.footer__contacts-inner {
	width: 470px;
	padding: 70px 20px 0 20px;
}

.footer__contacts-heading {
	margin: 0 0 50px 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 40px;
	font-weight: 500;
}

.footer__contacts-copy {
	margin: 0 0 30px 0;
	opacity: 0.75;
	font-size: 14px;
}

.footer__contacts-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 30px;
}

.footer__contacts-icon {
	width: 30px;
	height: 30px;
	margin-right: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.footer__contacts-icon--address {
	background-image: url("../img/icons/map.svg");
}

.footer__contacts-icon--tel {
	background-image: url("../img/icons/tel.svg");
}

.footer__contacts-icon--mail {
	background-image: url("../img/icons/mail.svg");
}

.footer__contacts-text {
	margin: 0;
	font-size: 20px;
}

.popup {
	z-index: 9999;
	position: fixed;
	display: none;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(33, 33, 33, 0.75);
}

.popup[data-popup="mix"] .popup__desc {
	font-size: 16px;
}

.popup__window {
	position: relative;
	z-index: 2;
	width: 950px;
	padding: 30px 0 50px 50px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
	background-color: #fff;
	background-position: bottom right;
	background-repeat: no-repeat;
}

.popup__window--quadrocopter {
	padding-right: 450px;
}

.popup__window--quadrocopter .popup__icon--1 {
	background-image: url("../img/icons/quadrocopter-1.svg");
}

.popup__window--quadrocopter .popup__icon--2 {
	background-image: url("../img/icons/quadrocopter-2.svg");
}

.popup__window--quadrocopter .popup__icon--3 {
	background-image: url("../img/icons/quadrocopter-3.svg");
}

.popup__window--quadrocopter .popup__icon--4 {
	background-image: url("../img/icons/quadrocopter-4.svg");
}

.popup__window--quadrocopter .popup__icon--5 {
	background-image: url("../img/icons/quadrocopter-5.svg");
}

.popup__window--quadrocopter .popup__icon--6 {
	background-image: url("../img/icons/quadrocopter-6.svg");
}

.popup__window--car {
	padding-right: 300px;
	padding-bottom: 110px;
}

.popup__window--car .popup__icon--1 {
	background-image: url("../img/icons/car-1.svg");
}

.popup__window--car .popup__icon--2 {
	background-image: url("../img/icons/car-2.svg");
}

.popup__window--car .popup__icon--3 {
	background-image: url("../img/icons/car-3.svg");
}

.popup__window--car .popup__icon--4 {
	background-image: url("../img/icons/car-4.svg");
}

.popup__window--car .popup__icon--5 {
	background-image: url("../img/icons/car-5.svg");
}

.popup__window--mix {
	padding-right: 110px;
}

.popup__window--reserve {
	min-height: 500px;
	padding-right: 350px;
}

.popup__window--autonomy {
	padding-right: 280px;
	background-position: 105% -50%;
}

.popup__window--industrial {
	padding-right: 270px;
}

.popup__close {
	position: absolute;
	top: 50px;
	right: 50px;
	width: 35px;
	height: 35px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	cursor: pointer;
	background-color: #F8F8F8;
	background-image: url("../img/icons/close.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.popup__heading {
	min-height: 72px;
	margin: 0 0 15px 0;
	text-transform: uppercase;
	line-height: 36px;
	color: #06346F;
	font-family: Magistral, sans-serif;
	font-size: 24px;
}

.popup__desc {
	margin-bottom: 20px;
	color: #06346F;
	font-size: 14px;
}

.popup__subheading {
	margin: 0 0 20px 0;
	color: #06346F;
	font-size: 18px;
}

.popup__list-heading {
	margin: 0 0 15px 0;
	color: #06346F;
	font-size: 14px;
}

.popup__list {
	margin: 0;
	padding: 0 110px 0 0;
}

.popup__list-item {
	position: relative;
	display: block;
	margin-bottom: 13px;
	padding-left: 15px;
	color: #06346F;
	font-size: 14px;
}

.popup__list-item:before {
	content: '';
	position: relative;
	display: block;
	top: .8em;
	left: -15px;
	width: 5px;
	height: 5px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #88C9FD;
}

.popup__row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}

.popup__icon {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 35px;
	height: 35px;
	margin-right: 20px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.popup__text {
	margin: 0;
	color: #06346F;
}

.popup__specification {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 35px;
}

.popup__specification .popup__desc {
	width: 325px;
	font-size: 12px;
}

.popup__formula {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	margin-right: 25px;
}

.popup__formula-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.popup__formula-item--centered {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.popup__formula-icon {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.popup__formula-icon--methane {
	width: 60px;
	height: 70px;
	margin-bottom: 5px;
	background-image: url("../img/icons/methane.png");
}

.popup__formula-icon--plus {
	width: 17px;
	height: 16px;
	margin: 0 12px;
	background-image: url("../img/icons/plus.svg");
}

.popup__formula-icon--hydrogen {
	width: 50px;
	height: 40px;
	margin-bottom: 18px;
	background-image: url("../img/icons/hydrogen.png");
}

.popup__formula-text {
	margin: 0;
	text-align: center;
	font-size: 12px;
}

.popup__characteristics {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.popup__characteristics .popup__list {
	width: 340px;
	padding-right: 0;
}

.popup__characteristics .popup__list-heading {
	font-size: 16px;
}

.popup__emissions-graphs {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	width: 275px;
	padding-bottom: 10px;
	border-bottom: 1px solid #f4f4f4;
}

.popup__emissions-item {
	position: relative;
	width: 45px;
	height: 185px;
	background-color: #f2f2f2;
	-webkit-border-radius: 30px;
	border-radius: 30px;
}

.popup__emissions-percentage {
	margin: 0 auto;
	padding-top: 10px;
	text-align: center;
	font-size: 18px;
}

.popup__emissions-fill {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	-webkit-border-radius: 30px;
	border-radius: 30px;
	background-image: linear-gradient(8.14deg, #33B3EA 6.65%, #3692FE 100%);
}

.popup__emissions-fill--50 {
	height: 95px;
}

.popup__emissions-fill--23 {
	height: 64px;
}

.popup__emissions-fill--16 {
	height: 44px;
}

.popup__emissions-fill--7 {
	height: 23px;
}

.popup__emissions-desc {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-top: 5px;
	width: -webkit-calc(100% + 7px);
	width: calc(100% + 7px);
}

.popup__emissions-desc-item {
	width: 50px;
	text-align: center;
}

.popup__emissions-desc-text {
	display: inline-block;
	opacity: .5;
}

.popup__emissions-desc-text sub {
	font-size: 0.6em;
	vertical-align: baseline;
}

.popup__emissions-desc-icon {
	display: inline-block;
	width: 8px;
	height: 14px;
	margin-bottom: -2px;
	padding-left: 2px;
	background-image: url("../img/icons/arrow.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.scheme {
	position: relative;
	min-width: 1340px;
	width: 2029px;
	-webkit-transform-origin: left top;
	-ms-transform-origin: left top;
	transform-origin: left top;
}

.scheme svg {
	width: 100%;
}

.scheme__arrow {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 32px;
	height: 6px;
	-webkit-animation-name: arrow-anim;
	animation-name: arrow-anim;
	-webkit-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-timing-function: ease-out;
	animation-timing-function: ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.scheme__arrow--pump {
	position: absolute;
	-webkit-transform: scale(1.5);
	-ms-transform: scale(1.5);
	transform: scale(1.5);
}

.scheme__arrow--1 {
	top: 41%;
	left: 15%;
}

.scheme__arrow--2 {
	top: 47.4%;
	left: 15%;
}

.scheme__arrow--3 {
	top: 36%;
	left: 27%;
}

.scheme__arrow--4 {
	top: 74.6%;
	left: 19%;
}

.scheme__arrow--5 {
	top: 74.6%;
	left: 31%;
}

.scheme__arrow--6 {
	top: 15%;
	left: 44%;
}

.scheme__arrow--7 {
	top: 40.6%;
	left: 53.5%;
}

.scheme__arrow--8 {
	top: 26%;
	left: 61.5%;
}

.scheme__arrow--9 {
	top: 40.6%;
	left: 68%;
}

.scheme__arrow--10 {
	top: 40.6%;
	left: 79%;
}

.scheme__arrow--11 {
	top: 40.6%;
	left: 90%;
}

.scheme__arrow--12 {
	z-index: 2;
	top: 5px;
	left: 86px;
	width: 19px !important;
	-webkit-animation-name: arrow-anim-vert;
	animation-name: arrow-anim-vert;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.scheme__arrow--13 {
	z-index: 2;
	top: 153px;
	right: 192px;
}

.scheme__arrow--14 {
	z-index: 2;
	top: 153px;
	right: 10px;
}

.scheme__arrow--15 {
	top: 47.4%;
	left: 23.5%;
}

.scheme__arrow--16 {
	top: 45.6%;
	left: 35.5%;
}

.scheme__arrow--17 {
	top: 66.7%;
	left: 27.5%;
}

.scheme__arrow--18 {
	top: 66.77%;
	left: 37%;
}

.scheme__arrow--19 {
	top: 61%;
	left: 30.4%;
	-webkit-animation-name: arrow-anim-vert;
	animation-name: arrow-anim-vert;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.scheme__arrow--20 {
	top: 61%;
	left: 39.65%;
	-webkit-animation-name: arrow-anim-vert;
	animation-name: arrow-anim-vert;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.scheme__flow {
	position: absolute;
	width: 30px;
	height: 15px;
}

.scheme__flow-item {
	position: absolute;
	top: 0;
	left: 0;
	width: 3px;
	height: 15px;
	background-image: url("../img/icons/ellipse.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-animation-name: flow;
	animation-name: flow;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

.scheme__flow-item:nth-child(2) {
	-webkit-animation-delay: .4s;
	animation-delay: .4s;
}

.scheme__flow-item:nth-child(3) {
	-webkit-animation-delay: .8s;
	animation-delay: .8s;
}

.scheme__flow-item:nth-child(4) {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

.scheme__flow-item:nth-child(5) {
	-webkit-animation-delay: 1.6s;
	animation-delay: 1.6s;
}

.scheme__flow-item:nth-child(6) {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.scheme__flow--1 {
	top: 46.7%;
	left: 20%;
}

.scheme__flow--2 {
	top: 73.9%;
	left: 27.6%;
}

.scheme__flow--3 {
	top: 56.5%;
	left: 26.3%;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.scheme__flow--4 {
	top: 27.5%;
	left: 49.35%;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.scheme__flow--5 {
	z-index: 2;
	top: 67px;
	left: 56px;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.scheme__flow--6 {
	z-index: 2;
	top: 67px;
	left: 107px;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.scheme__pump {
	position: absolute;
	top: 37.4%;
	left: 20.6%;
	width: 16px;
	height: 16px;
	-webkit-animation-name: pump;
	animation-name: pump;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-duration: 2.8s;
	animation-duration: 2.8s;
	background-image: url("../img/icons/pump.svg");
	background-size: contain;
	background-repeat: no-repeat;
}

.scheme__pulse {
	position: absolute;
	top: 41%;
	left: 75%;
	width: 45px;
	height: 45px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0.4;
	cursor: pointer;
}

.scheme__pulse-item {
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-direction: normal;
	animation-direction: normal;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	background-color: #1FAFE8;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.scheme__pulse-item:nth-child(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.scheme__pulse-item:nth-child(2) {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.scheme__pulse-item:nth-child(3) {
	-webkit-animation-delay: 1.5s;
	animation-delay: 1.5s;
}

.scheme__clear {
	display: none;
	position: absolute;
	top: 6%;
	left: 66%;
	width: 415px;
	height: 220px;
}

.scheme__clear .scheme__arrows {
	width: 24px;
}

.scheme__clear-sca {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 0;
	width: 190px;
}

.scheme__clear-membrane {
	position: absolute;
	z-index: 1;
	bottom: 11px;
	right: 0;
	width: 212px;
}

.scheme__tag {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 22px;
	padding-top: 5px;
	padding-bottom: 7px;
	background-color: #1FAFE8;
	cursor: pointer;
}

.scheme__tag:hover .scheme__tag-circle {
	border-color: #fff;
	background-color: #1fafe8;
}

.scheme__tag--active {
	cursor: default;
}

.scheme__tag-circle {
	position: absolute;
	top: 50%;
	left: 3px;
	width: 15px;
	height: 15px;
	background-color: #fff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid #1FAFE8;
	-webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
}

.scheme__tag-text {
	margin: 0;
	color: #fff;
	font-size: 16px;
}

.scheme__tag--1 {
	top: 23%;
	left: 25%;
	width: 180px;
}

.scheme__tag--2 {
	top: 58%;
	left: 16.5%;
	width: 140px;
}

.scheme__tag--3 {
	top: 21%;
	left: 52%;
	width: 155px;
}

.scheme__tag--4 {
	top: 47%;
	left: 72%;
	width: 200px;
}

.scheme__bubbles {
	overflow: hidden;
	position: absolute;
	width: 6.5%;
	height: 3.6%;
	top: 41%;
	right: 6.9%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.scheme__bubbles-item {
	position: absolute;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-duration: 4.9s;
	animation-duration: 4.9s;
}

.scheme__bubbles-item--1 {
	top: 3px;
	left: -1px;
	-webkit-animation-name: bubbles-1;
	animation-name: bubbles-1;
}

.scheme__bubbles-item--2 {
	bottom: -4px;
	left: 3px;
	-webkit-animation-name: bubbles-2;
	animation-name: bubbles-2;
}

.scheme__bubbles-item--3 {
	top: 0;
	right: -10px;
	-webkit-animation-name: bubbles-3;
	animation-name: bubbles-3;
}

.scheme__bubbles-item--4 {
	top: 1px;
	left: -19px;
	-webkit-animation-name: bubbles-4;
	animation-name: bubbles-4;
}

.scheme__flame {
	position: absolute;
	top: 27.5%;
	left: 40.6%;
	width: 100px;
	height: 100px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background-image: url("../img/flame.gif");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.scheme__desc {
	z-index: 3;
	display: none;
	position: absolute;
	top: 55%;
	left: 58%;
	width: 530px;
	padding: 20px 15px 25px 15px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	color: #06346F;
	background-color: #F0FBFF;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.scheme__desc-heading {
	margin: 0 0 15px 0;
	text-transform: uppercase;
	font-size: 16px;
}

.scheme__desc-text {
	display: none;
}

.scheme__desc p {
	padding-left: 10px;
	border-left: 2px solid #06346F;
	line-height: 1.4em;
	font-size: 14px;
}

.scheme__line {
	z-index: 2;
	display: none;
	position: absolute;
	width: 1px;
	background-color: #93CBFF;
}

.scheme__line--1 {
	top: 51%;
	left: 48.5%;
	height: 160px;
	-webkit-transform: rotate(-32deg) translate(-50%, -50%);
	-ms-transform: rotate(-32deg) translate(-50%, -50%);
	transform: rotate(-32deg) translate(-50%, -50%);
}

.scheme__line--2 {
	top: 47.5%;
	left: 49.2%;
	height: 180px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.scheme__line--3 {
	top: 48.3%;
	left: 62.1%;
	height: 230px;
	-webkit-transform: rotate(52deg) translate(-50%, -50%);
	-ms-transform: rotate(52deg) translate(-50%, -50%);
	transform: rotate(52deg) translate(-50%, -50%);
}

.scheme__line--4 {
	top: 36.5%;
	left: 49%;
	height: 350px;
	-webkit-transform: rotate(-90deg) translate(-50%, -50%);
	-ms-transform: rotate(-90deg) translate(-50%, -50%);
	transform: rotate(-90deg) translate(-50%, -50%);
}

.scheme-electricity .scheme__arrow--1 {
	top: 42%;
	left: 11%;
}

.scheme-electricity .scheme__arrow--2 {
	top: 47.7%;
	left: 11%;
}

.scheme-electricity .scheme__arrow--3 {
	top: 37.4%;
	left: 20%;
}

.scheme-electricity .scheme__arrow--4 {
	top: 72.1%;
	left: 14%;
}

.scheme-electricity .scheme__arrow--5 {
	top: 72.1%;
	left: 23.5%;
}

.scheme-electricity .scheme__arrow--6 {
	top: 18.6%;
	left: 33%;
}

.scheme-electricity .scheme__arrow--7 {
	top: 41.6%;
	left: 40%;
}

.scheme-electricity .scheme__arrow--8 {
	top: 28.4%;
	left: 46.2%;
}

.scheme-electricity .scheme__arrow--9 {
	top: 41.6%;
	left: 50.5%;
}

.scheme-electricity .scheme__arrow--10 {
	top: 41.6%;
	left: 59%;
}

.scheme-electricity .scheme__arrow--11 {
	top: 41.6%;
	left: 67.7%;
}

.scheme-electricity .scheme__arrow--15 {
	top: 47.7%;
	left: 17.5%;
}

.scheme-electricity .scheme__arrow--16 {
	top: 46.1%;
	left: 26.5%;
}

.scheme-electricity .scheme__arrow--17 {
	top: 65%;
	left: 20.5%;
}

.scheme-electricity .scheme__arrow--18 {
	top: 65%;
	left: 27%;
}

.scheme-electricity .scheme__arrow--19 {
	top: 60%;
	left: 22.75%;
}

.scheme-electricity .scheme__arrow--20 {
	top: 56%;
	left: 29.75%;
}

.scheme-electricity .scheme__pump {
	top: 38.8%;
	left: 15.4%;
	-webkit-animation-name: pump-2;
	animation-name: pump-2;
}

.scheme-electricity .scheme__flow--1 {
	top: 47%;
	left: 15.1%;
}

.scheme-electricity .scheme__flow--2 {
	top: 71.4%;
	left: 20.7%;
}

.scheme-electricity .scheme__flow--3 {
	top: 56%;
	left: 19.7%;
}

.scheme-electricity .scheme__flow--4 {
	top: 30%;
	left: 37.1%;
}

.scheme-electricity .scheme__pulse {
	top: 42.1%;
	left: 56.5%;
}

.scheme-electricity .scheme__bubbles {
	width: 4.9%;
	height: 3.1%;
	top: 41.9%;
	right: 29.9%;
}

.scheme-electricity .scheme__tag--1 {
	top: 25%;
	left: 18%;
}

.scheme-electricity .scheme__tag--2 {
	top: 56%;
	left: 12%;
}

.scheme-electricity .scheme__tag--3 {
	top: 23%;
	left: 39.5%;
}

.scheme-electricity .scheme__tag--4 {
	top: 48%;
	left: 54%;
}

.scheme-electricity .scheme__flame {
	top: 29.5%;
	left: 30.1%;
	-webkit-transform: scale(0.8) translate(-50%, -50%);
	-ms-transform: scale(0.8) translate(-50%, -50%);
	transform: scale(0.8) translate(-50%, -50%);
}

.scheme-electricity .scheme__clear {
	top: 10%;
	left: 49.5%;
}

.scheme-electricity .scheme__desc {
	top: 55%;
	left: 47%;
}

.scheme-electricity .scheme__line--1 {
	top: 49%;
	left: 36%;
	height: 110px;
}

.scheme-electricity .scheme__line--2 {
	top: 49%;
	left: 37%;
}

.scheme-electricity .scheme__line--3 {
	top: 49%;
	left: 46%;
}

.scheme-electricity .scheme__line--4 {
	top: 36.5%;
	left: 38.8%;
}

.hythane {
	background-color: #E7F6FC;
}

.hythane .container {
	background-color: #FBFBFB;
}

.hythane .container:not(.hythane__container) {
	background-color: #fbfbfb;
}

.hythane__container {
	background-color: transparent !important;
	background-image: url("../img/hythane-bg.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.hythane__inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 70px 0 55px 0;
}

.hythane__col:first-child {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	max-width: 490px;
	width: 50%;
}

.hythane__col:last-child {
	padding-top: 40px;
	padding-left: 115px;
}

.hythane__text {
	margin-bottom: 20px;
	line-height: 29px;
}

.hythane__subheading {
	margin: 0 0 10px 0;
	line-height: 23px;
	font-size: 16px;
	font-weight: 600;
}

.hythane__list {
	margin: 0;
	padding: 0;
}

.hythane__list-item {
	position: relative;
	display: block;
	margin-bottom: 10px;
	padding-left: 17px;
	line-height: 29px;
}

.hythane__list-item:before {
	content: '';
	display: block;
	position: absolute;
	top: 12px;
	left: 0;
	width: 5px;
	height: 5px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background-color: #3E90D8;
}

.hythane__ballon {
	width: 291px;
	height: 578px;
	background-image: url("../img/balloon.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.hythane__scheme {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 1440px;
	margin: 0 auto;
	padding: 30px 50px 15px 50px;
}

.hythane__scheme-inner {
	width: -webkit-calc(100% - 450px);
	width: calc(100% - 450px);
}

.hythane__scheme-inner svg {
	width: 100%;
}

.hythane__info {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 440px;
	padding: 30px 50px 15px 20px;
}

.hythane__info-primary {
	position: relative;
	margin-bottom: 22px;
	padding: 20px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background-color: #E4F5FD;
}

.hythane__info-primary:before {
	content: '';
	display: block;
	position: absolute;
	left: -20px;
	top: -webkit-calc(50% - 28px);
	top: calc(50% - 28px);
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 28px 20px 28px 0;
	border-color: transparent #e4f5fd transparent transparent;
}

.hythane__info-primary-text {
	margin: 0;
	line-height: 21px;
	font-size: 14px;
}

.hythane__info-primary-text sup {
	line-height: 1;
}

.hythane__info-sub {
	margin: 0;
	position: relative;
	padding-left: 20px;
	line-height: 22px;
	font-size: 14px;
}

.hythane__info-sub:not(:last-child) {
	margin-bottom: 15px;
}

.hythane__info-sub:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 35px;
	background-color: #3E90D8;
}

.car__desc {
	padding: 42px 0;
}

.car__graph {
	padding: 50px;
	background-color: #F5FAFF;
}

.car__wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding: 0 100px;
}

.car__col {
	width: -webkit-calc(50% - 40px);
	width: calc(50% - 40px);
}

.car__text {
	padding-right: 30px;
	line-height: 29px;
	font-weight: 300;
}

.car__text:not(:last-child) {
	margin-bottom: 20px;
}

.car__subheading {
	width: 430px;
	margin: 0;
	line-height: 25px;
	color: #0070B8;
	font-size: 18px;
}

.car__chart {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-bottom: 20px;
}

.car__park {
	margin: 0;
}

.car__stat {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	max-width: 490px;
	width: 100%;
	margin-left: -5px;
}

.car__icon {
	width: 39px;
	height: 39px;
	margin: 0 5px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.car__icon--full {
	background-image: url("../img/icons/car-full.svg");
}

.car__icon--half {
	background-image: url("../img/icons/car-half.svg");
}

.car__icon--empty {
	background-image: url("../img/icons/car-empty.svg");
}

.car__ratio {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 40px;
}

.car__ratio-text {
	position: relative;
	width: 240px;
	margin: 0 35px 0 0;
	padding: 14px 7px 8px 18px;
	background-color: #DEF4FF;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	font-size: 14px;
}

.car__ratio-text:before {
	content: '';
	display: block;
	position: absolute;
	right: -17px;
	top: -webkit-calc(50% - 17px);
	top: calc(50% - 17px);
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 17px 0 17px 17px;
	border-color: transparent transparent transparent #DEF4FF;
}

.car__ratio-clouds {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.car__cloud {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 75px;
	height: 50px;
	margin: 0;
	padding: 10px 0 0 0;
	color: #fff;
	font-size: 18px;
	background-image: url("../img/icons/cloud-icon.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.car__cloud sub {
	margin-bottom: -0.5em;
}

.car__ejections {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.car__ejections-icons {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 73px;
	height: 101px;
	margin-right: 15px;
	background-image: url("../img/icons/ejections-icons.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.car__ejections-text {
	width: 340px;
	margin: 0;
	line-height: 22px;
	font-size: 14px;
}

.car__plus {
	width: 15px;
	height: 15px;
	margin: 0 7px;
	background-image: url("../img/icons/plus.svg");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.car__emissions {
	width: 275px;
	margin-left: 80px;
}

.car__emissions .popup__list-heading {
	font-size: 20px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

.ps {
	overflow: auto !important;
}

}

@media only screen and (min-width: 1780px) {

.fuel__scroll {
	display: none;
}

}

@media only screen and (min-width: 2109px) {

.electricity__scroll {
	display: none;
}

}

@media only screen and (max-width: 1020px) {

.wrapper {
	max-width: 320px;
	padding: 0 15px;
}

.preloader__logo svg {
	max-width: -webkit-calc(100% - 30px);
	max-width: calc(100% - 30px);
	margin-left: 15px;
}

.header {
	padding: 15px 0;
}

.header--opened {
	background-color: transparent !important;
}

.header__list {
	right: auto;
	left: -230px;
	width: 230px;
	padding: 80px 20px 20px 15px;
}

.header__list--opened {
	left: 0;
}

.header__list-item {
	margin-bottom: 10px;
	line-height: 16px;
	font-size: 12px;
}

.header__list-dropdown {
	margin-bottom: 10px;
}

.header__list-arrow {
	top: 6px;
}

.header__list-links {
	padding-bottom: 0;
}

.header__list-links-item {
	margin: 0 0 10px 0;
	font-size: 10px;
}

.header--white {
	padding: 5px 0;
}

.header--white .header__hamburger {
	background-color: #0e6abf;
}

.header--white .header__hamburger-item {
	background-color: #fff;
}

.header--alt:not(.header--white) .header__hamburger {
	background-color: #fff;
}

.header--alt:not(.header--white) .header__hamburger-item {
	background-color: #0070B8;
}

.header--alt:not(.header--white) .header__logo {
	background-image: url("../img/logo-small-white.svg");
}

.header--opened {
	background-color: #fff;
}

.header--opened .header__hamburger {
	background-color: #fff !important;
}

.header--opened .header__hamburger-item {
	background-color: #0070B8 !important;
}

.header--opened .header__logo {
	background-image: url("../img/logo-small.svg") !important;
}

.header--opened .header__hamburger-item:first-child {
	-webkit-transform: translateX(1px) rotate(45deg);
	-ms-transform: translateX(1px) rotate(45deg);
	transform: translateX(1px) rotate(45deg);
}

.header--opened .header__hamburger-item:nth-child(2) {
	opacity: 0;
}

.header--opened .header__hamburger-item:last-child {
	-webkit-transform: translateX(1px) rotate(-45deg);
	-ms-transform: translateX(1px) rotate(-45deg);
	transform: translateX(1px) rotate(-45deg);
}

.header--opened .header__link {
	color: #0e6abf !important;
}

.header__inner {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.header__logo {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
	width: 25px;
	height: 50px;
	background-image: url("../img/logo-small.svg");
	-webkit-transform: translateX(10px);
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}

.header__nav {
	display: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding: 5px 0;
}

.header__link {
	display: block;
}

.header__lang {
	display: none;
}

.header__lang--mobile {
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
	display: block;
}

.header__lang-item {
	font-size: 12px;
}

.header__hamburger {
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.header__hamburger-item {
	width: 12px;
	height: 1px;
	margin: 1.5px 0;
}

.header__empty {
	display: block;
	width: 30px;
}

.header__hamburger {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	margin-right: 0;
}

.hero {
	height: 300px;
	background-image: url("../img/hero.jpg");
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
}

.hero__inner {
	min-height: 0;
	height: 300px;
}

.hero__video {
	display: none;
}

.hero__molecule--1 {
	width: 60px;
	height: 95px;
}

.hero__molecule--2 {
	display: none;
}

.hero__molecule--3 {
	top: 65%;
	left: -50px;
	width: 86px;
	height: 66px;
}

.hero__molecule--4 {
	top: 71%;
	right: 28%;
	width: 90px;
	height: 70px;
}

.hero__bubble {
	display: none;
}

.hero__block {
	width: 100%;
	height: 100px;
	margin-bottom: 50px;
	padding: 20px 5px 20px 10px;
}

.hero__heading {
	line-height: 27px;
	font-size: 18px;
}

.hero__hydrogen {
	width: 120px;
	height: 90px;
}

.hero .scroll-cta {
	display: none;
}

.about {
	margin-bottom: 15px;
	padding-top: 55px;
}

.about__blot {
	display: none;
}

.about__image {
	display: none;
}

.about__wrap {
	max-width: none;
	padding: 0;
}

.about__content {
	margin-left: 0;
}

.about__text {
	line-height: 16px;
	font-size: 11px;
}

.about__heading {
	margin: 15px 0 10px 0;
	font-size: 11px;
}

.about__list-item {
	margin-bottom: 10px;
	padding-left: 20px;
	line-height: 16px;
	font-size: 11px;
}

.about__list-item:before {
	top: 2px;
	width: 12px;
	height: 12px;
}

.heading {
	margin: 0 auto 10px auto;
}

.heading__inner {
	width: 255px;
	line-height: 27px;
	font-size: 18px;
}

.specs {
	padding-top: 15px;
	padding-bottom: 45px;
}

.specs__blot {
	display: none;
}

.specs__wrap {
	width: -webkit-calc(100% + 30px);
	width: calc(100% + 30px);
	margin-left: -15px;
	overflow-x: scroll;
}

.specs__table {
	width: 750px;
	padding: 0 15px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.specs__cell {
	width: 95px;
	padding: 5px;
	font-size: 11px;
}

.specs__cell--heading {
	position: relative;
	width: 145px;
	line-height: 14px;
	font-size: 10px;
}

.usage {
	padding-top: 40px;
	padding-bottom: 30px;
}

.usage__molecule {
	display: none;
}

.usage__inner {
	display: block;
}

.usage__heading {
	width: 140px;
	margin: 0 0 15px 0;
	line-height: 27px;
	font-size: 18px;
}

.usage__heading:after {
	display: none;
}

.usage__grid {
	width: 100%;
}

.usage__col {
	width: -webkit-calc(50% - 9px);
	width: calc(50% - 9px);
}

.usage__item {
	margin-bottom: 20px;
}

.usage__round {
	width: 37px;
	height: 37px;
	margin: 0 auto -10px auto;
}

.usage__round svg {
	width: 60%;
	height: auto;
}

.usage__block {
	width: 100%;
	height: 65px;
	padding: 5px;
	padding-bottom: 15px;
}

.usage__block-text {
	padding: 0;
	line-height: 9px;
	font-size: 9px;
}

.usage__block-pulse {
	bottom: 10px;
	width: 10px;
	height: 10px;
}

.fuel .container:not(.scheme-fuel) {
	padding-top: 35px;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#F0FBFF), to(#F0FBFF));
	background-image: linear-gradient(0deg, #F0FBFF, #F0FBFF);
}

.fuel__desc {
	width: 100%;
	margin-bottom: 10px;
	font-size: 10px;
}

.fuel__calc {
	margin: 0 0 15px 0;
	font-size: 12px;
}

.fuel__scroll {
	display: none;
}

.calculator {
	width: 100%;
}

.calculator__eval {
	padding: 12px 24px 20px 10px;
}

.calculator__row {
	margin-bottom: 18px;
}

.calculator__text {
	width: 100px;
	font-size: 12px;
}

.calculator__label {
	width: 120px;
}

.calculator__label-input {
	width: 88px;
	padding: 5px 8px;
	font-size: 12px;
}

.calculator__label-text {
	width: 32px;
	padding: 5px;
	font-size: 11px;
}

.calculator__btn {
	width: 110px;
	margin-top: 30px;
	padding: 8px;
	font-size: 12px;
}

.calculator__sum {
	padding: 12px 10px;
}

.calculator__sum-heading {
	margin-bottom: 5px;
	font-size: 12px;
}

.calculator__sum-result {
	font-size: 12px;
}

.electricity .container:not(.scheme-electricity) {
	padding-top: 30px;
}

.electricity__content {
	width: 100%;
}

.electricity__desc {
	line-height: 16px;
	font-size: 11px;
}

.electricity__list {
	width: 100%;
	margin-bottom: 10px;
}

.electricity__list-item {
	margin-bottom: 10px;
	padding-left: 20px;
	line-height: 16px;
	font-size: 11px;
}

.electricity__list-item:before {
	top: 2px;
	width: 12px;
	height: 12px;
}

.electricity__wrap {
	width: -webkit-calc(100% + 30px);
	width: calc(100% + 30px);
	margin-left: -15px;
	overflow-x: scroll;
}

.electricity__table {
	width: 700px;
	padding: 0 15px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.electricity__cell {
	width: 140px !important;
	padding: 5px;
	font-size: 11px;
}

.electricity__cell--heading {
	width: 140px;
	line-height: 1.4em;
	font-size: 10px;
}

.electricity__hint {
	margin-top: 15px;
	font-size: 9px;
}

.electricity__scroll {
	display: none;
}

.certificates {
	padding: 25px 0 50px 0;
}

.certificates__grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	overflow-x: scroll;
}

.certificates__item {
	width: 100px;
	height: 140px;
	margin-right: 12px;
}

.certificates__btn {
	width: 85px;
	padding: 5px;
	font-size: 11px;
}

.redirect {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
}

.redirect__col {
	width: 100%;
}

.redirect__content {
	width: 320px;
	margin: 0 auto;
	padding: 25px 15px 18px 15px;
}

.redirect__heading {
	margin-bottom: 7px;
	line-height: 25px;
	font-size: 18px;
}

.redirect__desc {
	margin-bottom: 10px;
	padding-right: 30px;
	line-height: 16px;
	font-size: 11px;
}

.redirect__btn {
	width: 125px;
	padding: 10px;
	font-size: 12px;
}

.redirect__ref {
	z-index: 2;
}

.redirect__molecule {
	z-index: 1;
	right: 0;
	bottom: 30px;
}

.redirect__molecule--hydrogen {
	width: 85px;
	height: 110px;
}

.redirect__molecule--methane {
	width: 90px;
	height: 110px;
}

.footer {
	height: auto;
}

.footer--inner {
	display: none;
}

.footer--mobile {
	position: relative;
	display: block;
}

.footer__map {
	display: none;
}

.footer__contacts {
	width: 100%;
	height: auto;
	padding: 10px 0;
}

.footer__contacts-heading {
	display: none;
}

.footer__contacts-inner {
	max-width: 320px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
}

.footer__contacts-row {
	margin-bottom: 10px;
}

.footer__contacts-icon {
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 12px;
	height: 12px;
	margin-right: 12px;
}

.footer__contacts-text {
	font-size: 10px;
}

.footer__contacts-copy {
	width: 100%;
	margin-bottom: 0;
	opacity: 0.3;
	color: #212121;
	text-align: center;
	font-size: 9px;
}

.popup__close {
	top: 10px;
	right: 10px;
}

.popup__window {
	max-width: 400px;
	max-height: -webkit-calc(100% - 30px);
	max-height: calc(100% - 30px);
	width: -webkit-calc(100% - 20px);
	width: calc(100% - 20px);
	overflow-y: scroll;
	padding: 15px;
	background-image: none !important;
	background-color: #F0FBFF;
}

.popup__window--mix {
	background-color: #fff;
}

.popup__heading {
	min-height: 40px;
	margin-bottom: 10px;
	line-height: 1.4em;
	font-size: 14px;
}

.popup__subheading {
	margin-bottom: 10px;
	font-size: 12px;
}

.popup__row {
	margin-bottom: 10px;
}

.popup__icon {
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.popup__text {
	font-size: 12px;
}

.popup__list {
	padding-right: 0;
}

.popup__characteristics {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
}

.popup__characteristics-col {
	width: 100%;
}

.popup__emissions {
	width: 275px;
	margin: 0 auto;
}

.scheme {
	margin-left: -15px;
}

.hythane__col:first-child {
	width: 100%;
	padding-top: 25px;
	padding-bottom: 30px;
}

.hythane__col:last-child {
	display: none;
}

.hythane__text {
	line-height: 17px;
	font-size: 11px;
}

.hythane__subheading {
	line-height: 16px;
	font-size: 11px;
}

.hythane__list-item {
	line-height: 16px;
	font-size: 11px;
}

.hythane__list-item:before {
	top: 5px;
}

.hythane__scheme {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	padding-bottom: 15px;
}

.hythane__scheme-inner {
	width: 100%;
	margin-bottom: 20px;
}

.hythane__scheme-inner svg {
	height: auto;
}

.hythane__info {
	width: 100%;
	padding: 0;
}

.hythane__info-primary {
	margin-bottom: 15px;
	padding: 13px 40px 10px 15px;
}

.hythane__info-primary:before {
	top: -16px;
	left: -webkit-calc(50% - 28px);
	left: calc(50% - 28px);
	border-width: 0 28px 16px 28px;
	border-color: transparent transparent #e4f5fd transparent;
}

.hythane__info-primary-text {
	line-height: 17px;
	font-size: 11px;
}

.hythane__info-sub {
	margin-bottom: 10px;
	padding-left: 13px;
	line-height: 17px;
	font-size: 11px;
}

.hythane__info-sub:before {
	height: 25px;
}

.car__graph {
	padding: 35px 0 25px 0;
}

.car__wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	width: 320px;
	margin: 0 auto;
	padding: 0 15px;
}

.car__col {
	width: 100%;
}

.car__text {
	margin: 0 0 15px 0 !important;
	padding: 0;
	line-height: 17px;
	font-size: 11px;
}

.car__subheading {
	width: 100%;
	margin-bottom: 5px;
	line-height: 19px;
	font-size: 14px;
}

.car__chart {
	margin-bottom: 15px;
}

.car__chart svg {
	width: 100%;
	height: auto;
}

.car__park {
	line-height: 15px;
	font-size: 11px;
}

.car__icon {
	width: 28px;
	height: 28px;
	margin: 0 4px;
}

.car__icon:nth-child(17),
.car__icon:nth-child(18),
.car__icon:nth-child(19),
.car__icon:nth-child(20) {
	display: none;
}

.car__ratio {
	margin-bottom: 18px;
}

.car__ratio-text {
	margin-right: 20px;
	padding: 15px;
	line-height: 17px;
	font-size: 11px;
}

.car__ratio-clouds {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
}

.car__cloud {
	width: 56px;
	height: 36px;
	padding-top: 6px;
	line-height: 19px;
	font-size: 12px;
}

.car__plus {
	margin: 7px 0;
}

.car__ejections {
	margin-bottom: 15px;
}

.car__ejections-icons {
	width: 58px;
	height: 88px;
	margin-right: 20px;
}

.car__ejections-text {
	line-height: 17px;
	font-size: 11px;
}

.car__emissions {
	margin: 0 auto;
}

}

@-webkit-keyframes flickerAnimation {

0% {
	opacity: 1;
}

50% {
	opacity: 0;
}

100% {
	opacity: 1;
}

}

@keyframes flickerAnimation {

0% {
	opacity: 1;
}

50% {
	opacity: 0;
}

100% {
	opacity: 1;
}

}

@-webkit-keyframes molecule-big-1 {

0%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

50% {
	-webkit-transform: translateX(10%);
	transform: translateX(10%);
}

}

@keyframes molecule-big-1 {

0%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

50% {
	-webkit-transform: translateX(10%);
	transform: translateX(10%);
}

}

@-webkit-keyframes molecule-big-2 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-36deg);
	transform: translateX(0) rotate(-36deg);
}

50% {
	-webkit-transform: translateX(10%) rotate(-36deg);
	transform: translateX(10%) rotate(-36deg);
}

}

@keyframes molecule-big-2 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-36deg);
	transform: translateX(0) rotate(-36deg);
}

50% {
	-webkit-transform: translateX(10%) rotate(-36deg);
	transform: translateX(10%) rotate(-36deg);
}

}

@-webkit-keyframes molecule-small-1 {

0%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

50% {
	-webkit-transform: translateX(15%);
	transform: translateX(15%);
}

}

@keyframes molecule-small-1 {

0%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

50% {
	-webkit-transform: translateX(15%);
	transform: translateX(15%);
}

}

@-webkit-keyframes molecule-small-2 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-67deg);
	transform: translateX(0) rotate(-67deg);
}

50% {
	-webkit-transform: translateX(15%) rotate(-67deg);
	transform: translateX(15%) rotate(-67deg);
}

}

@keyframes molecule-small-2 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-67deg);
	transform: translateX(0) rotate(-67deg);
}

50% {
	-webkit-transform: translateX(15%) rotate(-67deg);
	transform: translateX(15%) rotate(-67deg);
}

}

@-webkit-keyframes molecule-small-3 {

0%,100% {
	-webkit-transform: translateX(0) rotate(11deg);
	transform: translateX(0) rotate(11deg);
}

50% {
	-webkit-transform: translateX(-13%) rotate(11deg);
	transform: translateX(-13%) rotate(11deg);
}

}

@keyframes molecule-small-3 {

0%,100% {
	-webkit-transform: translateX(0) rotate(11deg);
	transform: translateX(0) rotate(11deg);
}

50% {
	-webkit-transform: translateX(-13%) rotate(11deg);
	transform: translateX(-13%) rotate(11deg);
}

}

@-webkit-keyframes molecule-small-4 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-105deg);
	transform: translateX(0) rotate(-105deg);
}

50% {
	-webkit-transform: translateX(17%) rotate(-105deg);
	transform: translateX(17%) rotate(-105deg);
}

}

@keyframes molecule-small-4 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-105deg);
	transform: translateX(0) rotate(-105deg);
}

50% {
	-webkit-transform: translateX(17%) rotate(-105deg);
	transform: translateX(17%) rotate(-105deg);
}

}

@-webkit-keyframes molecule-small-5 {

0%,100% {
	-webkit-transform: translateX(0) rotate(45deg);
	transform: translateX(0) rotate(45deg);
}

50% {
	-webkit-transform: translateX(20%) rotate(45deg);
	transform: translateX(20%) rotate(45deg);
}

}

@keyframes molecule-small-5 {

0%,100% {
	-webkit-transform: translateX(0) rotate(45deg);
	transform: translateX(0) rotate(45deg);
}

50% {
	-webkit-transform: translateX(20%) rotate(45deg);
	transform: translateX(20%) rotate(45deg);
}

}

@-webkit-keyframes molecule-small-6 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-24deg);
	transform: translateX(0) rotate(-24deg);
}

50% {
	-webkit-transform: translateX(10%) rotate(-24deg);
	transform: translateX(10%) rotate(-24deg);
}

}

@keyframes molecule-small-6 {

0%,100% {
	-webkit-transform: translateX(0) rotate(-24deg);
	transform: translateX(0) rotate(-24deg);
}

50% {
	-webkit-transform: translateX(10%) rotate(-24deg);
	transform: translateX(10%) rotate(-24deg);
}

}

@-webkit-keyframes molecule-small-7 {

0%,100% {
	-webkit-transform: translateX(0) rotate(15deg);
	transform: translateX(0) rotate(15deg);
}

50% {
	-webkit-transform: translateX(-15%) rotate(15deg);
	transform: translateX(-15%) rotate(15deg);
}

}

@keyframes molecule-small-7 {

0%,100% {
	-webkit-transform: translateX(0) rotate(15deg);
	transform: translateX(0) rotate(15deg);
}

50% {
	-webkit-transform: translateX(-15%) rotate(15deg);
	transform: translateX(-15%) rotate(15deg);
}

}

@-webkit-keyframes molecule {

0%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

50% {
	-webkit-transform: translateX(15%);
	transform: translateX(15%);
}

}

@keyframes molecule {

0%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

50% {
	-webkit-transform: translateX(15%);
	transform: translateX(15%);
}

}

@-webkit-keyframes pulse {

0% {
	opacity: 0;
	-webkit-transform: scale(0.1, 0.1);
	transform: scale(0.1, 0.1);
}

50% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}

}

@keyframes pulse {

0% {
	opacity: 0;
	-webkit-transform: scale(0.1, 0.1);
	transform: scale(0.1, 0.1);
}

50% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}

}

@-webkit-keyframes arrow-anim {

0% {
	opacity: 0;
	-webkit-transform: translateX(-25%);
	transform: translateX(-25%);
}

20%,50% {
	opacity: 1;
}

95% {
	opacity: 0;
}

100% {
	opacity: 0;
	-webkit-transform: translateX(95%);
	transform: translateX(95%);
}

}

@keyframes arrow-anim {

0% {
	opacity: 0;
	-webkit-transform: translateX(-25%);
	transform: translateX(-25%);
}

20%,50% {
	opacity: 1;
}

95% {
	opacity: 0;
}

100% {
	opacity: 0;
	-webkit-transform: translateX(95%);
	transform: translateX(95%);
}

}

@-webkit-keyframes arrow-anim-vert {

0% {
	opacity: 0;
	-webkit-transform: rotate(-90deg) translateX(-25%);
	transform: rotate(-90deg) translateX(-25%);
}

20%,50% {
	opacity: 1;
}

95% {
	opacity: 0;
}

100% {
	opacity: 0;
	-webkit-transform: rotate(-90deg) translateX(95%);
	transform: rotate(-90deg) translateX(95%);
}

}

@keyframes arrow-anim-vert {

0% {
	opacity: 0;
	-webkit-transform: rotate(-90deg) translateX(-25%);
	transform: rotate(-90deg) translateX(-25%);
}

20%,50% {
	opacity: 1;
}

95% {
	opacity: 0;
}

100% {
	opacity: 0;
	-webkit-transform: rotate(-90deg) translateX(95%);
	transform: rotate(-90deg) translateX(95%);
}

}

@-webkit-keyframes flow {

0% {
	opacity: 0;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

20%,50% {
	opacity: 1;
}

95% {
	opacity: 0;
}

100% {
	opacity: 0;
	-webkit-transform: translateX(30px);
	transform: translateX(30px);
}

}

@keyframes flow {

0% {
	opacity: 0;
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

20%,50% {
	opacity: 1;
}

95% {
	opacity: 0;
}

100% {
	opacity: 0;
	-webkit-transform: translateX(30px);
	transform: translateX(30px);
}

}

@-webkit-keyframes flow-2 {

0% {
	opacity: 0.9;
}

50% {
	opacity: 0.65;
}

100% {
	opacity: 1;
}

}

@keyframes flow-2 {

0% {
	opacity: 0.9;
}

50% {
	opacity: 0.65;
}

100% {
	opacity: 1;
}

}

@-webkit-keyframes flow-3 {

0%,100% {
	opacity: 1;
}

50% {
	opacity: 0.65;
}

}

@keyframes flow-3 {

0%,100% {
	opacity: 1;
}

50% {
	opacity: 0.65;
}

}

@-webkit-keyframes flow-4 {

0% {
	opacity: 0.9;
}

50% {
	opacity: 1;
}

100% {
	opacity: 0.65;
}

}

@keyframes flow-4 {

0% {
	opacity: 0.9;
}

50% {
	opacity: 1;
}

100% {
	opacity: 0.65;
}

}

@-webkit-keyframes flow-5 {

0% {
	opacity: 0.65;
}

50% {
	opacity: 1;
}

}

@keyframes flow-5 {

0% {
	opacity: 0.65;
}

50% {
	opacity: 1;
}

}

@-webkit-keyframes pump {

0% {
	-webkit-transform: scale(1.5) rotate(0deg);
	transform: scale(1.5) rotate(0deg);
}

100% {
	-webkit-transform: scale(1.5) rotate(-360deg);
	transform: scale(1.5) rotate(-360deg);
}

}

@keyframes pump {

0% {
	-webkit-transform: scale(1.5) rotate(0deg);
	transform: scale(1.5) rotate(0deg);
}

100% {
	-webkit-transform: scale(1.5) rotate(-360deg);
	transform: scale(1.5) rotate(-360deg);
}

}

@-webkit-keyframes pump-2 {

0% {
	-webkit-transform: rotate(0deg) scale(0.8);
	transform: rotate(0deg) scale(0.8);
}

100% {
	-webkit-transform: rotate(-360deg) scale(0.8);
	transform: rotate(-360deg) scale(0.8);
}

}

@keyframes pump-2 {

0% {
	-webkit-transform: rotate(0deg) scale(0.8);
	transform: rotate(0deg) scale(0.8);
}

100% {
	-webkit-transform: rotate(-360deg) scale(0.8);
	transform: rotate(-360deg) scale(0.8);
}

}

@keyframes pulse {

0% {
	opacity: 0;
	-webkit-transform: scale(0.1, 0.1);
	transform: scale(0.1, 0.1);
}

50% {
	opacity: 1;
}

100% {
	opacity: 0;
	-webkit-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}

}

@-webkit-keyframes bubbles-1 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(2px);
	transform: translateX(2px);
}

75% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

}

@keyframes bubbles-1 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(2px);
	transform: translateX(2px);
}

75% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

}

@-webkit-keyframes bubbles-2 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

75% {
	-webkit-transform: translateX(2px);
	transform: translateX(2px);
}

}

@keyframes bubbles-2 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
}

75% {
	-webkit-transform: translateX(2px);
	transform: translateX(2px);
}

}

@-webkit-keyframes bubbles-3 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
}

75% {
	-webkit-transform: translateX(3px);
	transform: translateX(3px);
}

}

@keyframes bubbles-3 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
}

75% {
	-webkit-transform: translateX(3px);
	transform: translateX(3px);
}

}

@-webkit-keyframes bubbles-4 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(3px);
	transform: translateX(3px);
}

75% {
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
}

}

@keyframes bubbles-4 {

0%,50%,100% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

25% {
	-webkit-transform: translateX(3px);
	transform: translateX(3px);
}

75% {
	-webkit-transform: translateX(-3px);
	transform: translateX(-3px);
}

}

