.scheme {
	position: relative;
	min-width: 1340px;
	width: 2029px;
	transform-origin: left top;
	svg {
		width: 100%; }
	&__arrow {
		position: absolute;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		width: 32px;
		height: 6px;
		animation: {
			name: arrow-anim;
			duration: 1.5s;
			timing-function: ease-out;
			iteration-count: infinite;
			fill-mode: forwards; }
		// &-item
		// 	position: absolute
		// 	top: 50%
		// 	left: 0
		// 	width: 0
		// 	height: 0
		// 	transform: translateY(-50%)
		// 	border-style: solid
		// 	border-width: 3px 0 3px 4px
		// 	border-color: transparent transparent transparent #4cbfed
		// 	animation:
		// 		timing-function: linear
		// 		iteration-count: infinite
		// 		duration: 1s
		&--pump {
			position: absolute;
			transform: scale(1.5);
			// .scheme__arrows-item
			// 	position: absolute
			// &--1
			// 	top: 36.9%
			// 	left: 14.7%
			// 	width: 16px
			// 	height: 17px
			// 	.scheme__arrows-item
			// 		animation-name: arrow-pump-anim-1
			// &--2
			// 	top: 33.3%
			// 	left: 13.2%
			// 	width: 12px
			// 	height: 17px
			// 	.scheme__arrows-item
 }			// 		animation-name: arrow-pump-anim-2
		&--1 {
			top: 41%;
			left: 15%; }
		&--2 {
			top: 47.4%;
			left: 15%; }
		&--3 {
			top: 36%;
			left: 27%; }
		&--4 {
			top: 74.6%;
			left: 19%; }
		&--5 {
			top: 74.6%;
			left: 31%; }
		&--6 {
			top: 15%;
			left: 44%; }
		&--7 {
			top: 40.6%;
			left: 53.5%; }
		&--8 {
			top: 26%;
			left: 61.5%; }
		&--9 {
			top: 40.6%;
			left: 68%; }
		&--10 {
			top: 40.6%;
			left: 79%; }
		&--11 {
			top: 40.6%;
			left: 90%; }
		&--12 {
			z-index: 2;
			top: 5px;
			left: 86px;
			width: 19px !important;
			animation-name: arrow-anim-vert;
			transform: rotate(-90deg); }
		&--13 {
			z-index: 2;
			top: 153px;
			right: 192px; }
		&--14 {
			z-index: 2;
			top: 153px;
			right: 10px; }
		&--15 {
			top: 47.4%;
			left: 23.5%; }
		&--16 {
			top: 45.6%;
			left: 35.5%; }
		&--17 {
			top: 66.7%;
			left: 27.5%; }
		&--18 {
			top: 66.77%;
			left: 37%; }
		&--19 {
			top: 61%;
			left: 30.4%;
			animation-name: arrow-anim-vert;
			transform: rotate(-90deg); }
		&--20 {
			top: 61%;
			left: 39.65%;
			animation-name: arrow-anim-vert;
			transform: rotate(-90deg); } }
	&__flow {
		position: absolute;
		width: 30px;
		height: 15px;
		&-item {
			position: absolute;
			top: 0;
			left: 0;
			width: 3px;
			height: 15px;
			background: {
				image: url('../img/icons/ellipse.svg');
				size: contain;
				position: center;
				repeat: no-repeat; }
			animation: {
				name: flow;
				timing-function: linear;
				iteration-count: infinite;
				duration: 2s; }
			&:nth-child(1) {
				// opacity: 0.65
				// animation-delay: .3s
 }				// animation-name: flow-1
			&:nth-child(2) {
				animation-delay: .4s;
				// opacity: 0.9
 }				// animation-name: flow-2
			&:nth-child(3) {
				animation-delay: .8s;
				// opacity: 1
 }				// animation-name: flow-3
			&:nth-child(4) {
				animation-delay: 1.2s;
				// opacity: 0.9
 }				// animation-name: flow-4
			&:nth-child(5) {
				animation-delay: 1.6s; }
			&:nth-child(6) {
				animation-delay: 2s; }
			// &:nth-child(7)
			// 	animation-delay: 1.4s
			/// opacity: 0.65
 }			/// animation-name: flow-5
		&--1 {
			top: 46.7%;
			left: 20%; }
		&--2 {
			top: 73.9%;
			left: 27.6%; }
		&--3 {
			top: 56.5%;
			left: 26.3%;
			transform: rotate(90deg); }
		&--4 {
			top: 27.5%;
			left: 49.35%;
			transform: rotate(90deg); }
		&--5 {
			z-index: 2;
			top: 67px;
			left: 56px;
			transform: rotate(90deg); }
		&--6 {
			z-index: 2;
			top: 67px;
			left: 107px;
			transform: rotate(90deg); } }
	&__pump {
		position: absolute;
		top: 37.4%;
		left: 20.6%;
		width: 16px;
		height: 16px;
		animation: {
			name: pump;
			timing-function: linear;
			iteration-count: infinite;
			duration: 2.8s; }
		background: {
			image: url('../img/icons/pump.svg');
			size: contain;
			repeat: no-repeat; } }
	&__pulse {
		position: absolute;
		top: 41%;
		left: 75%;
		width: 45px;
		height: 45px;
		transform: translate(-50%, -50%);
		opacity: 0.4;
		cursor: pointer;
		&-item {
			animation: {
				name: pulse;
				duration: 2s;
				timing-function: linear;
				iteration-count: infinite;
				direction: normal;
				fill-mode: forwards; }
			background-color: #1FAFE8;
			border-radius: 50%;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			&:nth-child(1) {
				animation-delay: 0s; }
			&:nth-child(2) {
				animation-delay: 1s; }
			&:nth-child(3) {
				animation-delay: 1.5s; } } }
	&__clear {
		display: none;
		position: absolute;
		top: 6%;
		left: 66%;
		width: 415px;
		height: 220px;
		.scheme__arrows {
			width: 24px; }
		&-sca {
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 190px; }
		&-membrane {
			position: absolute;
			z-index: 1;
			bottom: 11px;
			right: 0;
			width: 212px; } }
	&__tag {
		position: absolute;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		padding-left: 22px;
		padding-top: 5px;
		padding-bottom: 7px;
		background-color: #1FAFE8;
		cursor: pointer;
		&:hover {
			.scheme__tag-circle {
				border-color: #fff;
				background-color: #1fafe8; } }
		&--active {
			cursor: default; }
		&-circle {
			position: absolute;
			top: 50%;
			left: 3px;
			width: 15px;
			height: 15px;
			background-color: #fff;
			border-radius: 50%;
			border: 1px solid #1FAFE8;
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
			transform: translateY(-50%);
			transition: all .3s linear; }
		&-text {
			margin: 0;
			color: #fff;
			font-size: 16px; }
		&--1 {
			top: 23%;
			left: 25%;
			width: 180px; }
		&--2 {
			top: 58%;
			left: 16.5%;
			width: 140px; }
		&--3 {
			top: 21%;
			left: 52%;
			width: 155px; }
		&--4 {
			top: 47%;
			left: 72%;
			width: 200px; } }
	&__bubbles {
		overflow: hidden;
		position: absolute;
		width: 6.5%;
		height: 3.6%;
		top: 41%;
		right: 6.9%;
		transform: translate(-50%, -50%);
		&-item {
			position: absolute;
			animation: {
				timing-function: linear;
				iteration-count: infinite;
				duration: 4.9s; }
			&--1 {
				top: 3px;
				left: -1px;
				animation-name: bubbles-1; }
			&--2 {
				bottom: -4px;
				left: 3px;
				animation-name: bubbles-2; }
			&--3 {
				top: 0;
				right: -10px;
				animation-name: bubbles-3; }
			&--4 {
				top: 1px;
				left: -19px;
				animation-name: bubbles-4; } } }
	&__flame {
		position: absolute;
		top: 27.5%;
		left: 40.6%;
		width: 100px;
		height: 100px;
		transform: translate(-50%, -50%);
		background: {
			image: url('../img/flame.gif');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__desc {
		z-index: 3;
		display: none;
		position: absolute;
		top: 55%;
		left: 58%;
		width: 530px;
		padding: 20px 15px 25px 15px;
		border-radius: 5px;
		color: $new-blue;
		background-color: #F0FBFF;
		transform: translateX(-50%);
		&-heading {
			margin: 0 0 15px 0;
			text-transform: uppercase;
			font-size: 16px; }
		&-text {
			display: none; }
		p {
			padding-left: 10px;
			border-left: 2px solid $new-blue;
			line-height: 1.4em;
			font-size: 14px; } }
	&__line {
		z-index: 2;
		display: none;
		position: absolute;
		width: 1px;
		background-color: #93CBFF;
		&--1 {
			top: 51%;
			left: 48.5%;
			height: 160px;
			transform: rotate(-32deg) translate(-50%, -50%); }
		&--2 {
			top: 47.5%;
			left: 49.2%;
			height: 180px;
			transform: translate(-50%, -50%); }
		&--3 {
			top: 48.3%;
			left: 62.1%;
			height: 230px;
			transform: rotate(52deg) translate(-50%, -50%); }
		&--4 {
			top: 36.5%;
			left: 49%;
			height: 350px;
			transform: rotate(-90deg) translate(-50%, -50%); } }
	&-electricity .scheme {
		&__arrow {
			&--1 {
				top: 42%;
				left: 11%; }
			&--2 {
				top: 47.7%;
				left: 11%; }
			&--3 {
				top: 37.4%;
				left: 20%; }
			&--4 {
				top: 72.1%;
				left: 14%; }
			&--5 {
				top: 72.1%;
				left: 23.5%; }
			&--6 {
				top: 18.6%;
				left: 33%; }
			&--7 {
				top: 41.6%;
				left: 40%; }
			&--8 {
				top: 28.4%;
				left: 46.2%; }
			&--9 {
				top: 41.6%;
				left: 50.5%; }
			&--10 {
				top: 41.6%;
				left: 59%; }
			&--11 {
				top: 41.6%;
				left: 67.7%; }
			&--15 {
				top: 47.7%;
				left: 17.5%; }
			&--16 {
				top: 46.1%;
				left: 26.5%; }
			&--17 {
				top: 65%;
				left: 20.5%; }
			&--18 {
				top: 65%;
				left: 27%; }
			&--19 {
				top: 60%;
				left: 22.75%; }
			&--20 {
				top: 56%;
				left: 29.75%; }
			// &--pump
			// 	&--1
			// 		top: 39.9%
			// 		left: 12.8%
			// 	&--2
			// 		top: 37.3%
 }			// 		left: 11.6%
		&__pump {
			top: 38.8%;
			left: 15.4%;
			animation-name: pump-2; }
		&__flow {
			&--1 {
				top: 47%;
				left: 15.1%; }
			&--2 {
				top: 71.4%;
				left: 20.7%; }
			&--3 {
				top: 56%;
				left: 19.7%; }
			&--4 {
				top: 30%;
				left: 37.1%; } }
		&__pulse {
			top: 42.1%;
			left: 56.5%; }
		&__bubbles {
			width: 4.9%;
			height: 3.1%;
			top: 41.9%;
			right: 29.9%; }
		&__tag {
			&--1 {
				top: 25%;
				left: 18%; }
			&--2 {
				top: 56%;
				left: 12%; }
			&--3 {
				top: 23%;
				left: 39.5%; }
			&--4 {
				top: 48%;
				left: 54%; } }
		&__flame {
			top: 29.5%;
			left: 30.1%;
			transform: scale(0.8) translate(-50%,-50%); }
		&__clear {
			top: 10%;
			left: 49.5%; }
		&__desc {
			top: 55%;
			left: 47%; }
		&__line {
			&--1 {
				top: 49%;
				left: 36%;
				height: 110px; }
			&--2 {
				top: 49%;
				left: 37%; }
			&--3 {
				top: 49%;
				left: 46%; }
			&--4 {
				top: 36.5%;
				left: 38.8%; } } } }

@keyframes arrow-anim {
	0% {
		opacity: 0;
		transform: translateX(-25%); }

	20%, 50% {
		opacity: 1; }
	95% {
		opacity: 0; }
	100% {
		opacity: 0;
		transform: translateX(95%); } }

@keyframes arrow-anim-vert {
	0% {
		opacity: 0;
		transform: rotate(-90deg) translateX(-25%); }

	20%, 50% {
		opacity: 1; }
	95% {
		opacity: 0; }
	100% {
		opacity: 0;
		transform: rotate(-90deg) translateX(95%); }
	// 0%
	// 	left: 0
	// 	opacity: 0
	// 50%
	// 	opacity: 1
	// 100%
	// 	left: 100%
 }	// 	opacity: 0

// @keyframes arrow-pump-anim-1
// 	0%
// 		opacity: 0
// 		bottom: 0
// 		left: 0
// 		transform: rotate(-40deg)
// 	50%
// 		opacity: 1
// 	100%
// 		opacity: 0
// 		top: 0
// 		left: calc(100% - 3px)
// 		transform: rotate(-80deg)

// @keyframes arrow-pump-anim-2
// 	0%
// 		opacity: 0
// 		bottom: 0
// 		left: 0
// 		transform: rotate(-90deg)
// 	50%
// 		opacity: 1
// 	100%
// 		opacity: 0
// 		top: 0
// 		left: calc(100% - 3px)
// 		transform: rotate(-30deg)

@keyframes flow {
	0% {
		opacity: 0;
		transform: translateX(0); }

	20%, 50% {
		opacity: 1; }
	95% {
		opacity: 0; }
	100% {
		opacity: 0;
		transform: translateX(30px); } }

@keyframes flow-2 {
	0% {
		opacity: 0.9; }
	50% {
		opacity: 0.65; }
	100% {
		opacity: 1; } }

@keyframes flow-3 {
	0%,
	100% {
		opacity: 1; }
	50% {
		opacity: 0.65; } }

@keyframes flow-4 {
	0% {
		opacity: 0.9; }
	50% {
		opacity: 1; }
	100% {
		opacity: 0.65; } }

@keyframes flow-5 {
	0% {
		opacity: 0.65; }
	50% {
		opacity: 1; } }

@keyframes pump {
	0% {
		transform: scale(1.5) rotate(0deg); }
	100% {
		transform: scale(1.5) rotate(-360deg); } }

@keyframes pump-2 {
	0% {
		transform: rotate(0deg) scale(0.8); }
	100% {
		transform: rotate(-360deg) scale(0.8); } }

@keyframes pulse {
	0% {
		opacity: 0;
		transform: scale(0.1, 0.1); }
	50% {
		opacity: 1; }
	100% {
		opacity: 0;
		transform: scale(1.2, 1.2); } }

@keyframes bubbles-1 {
	0%,
	50%,
	100% {
		transform: translateX(0); }
	25% {
		transform: translateX(2px); }
	75% {
		transform: translateX(-2px); } }

@keyframes bubbles-2 {
	0%,
	50%,
	100% {
		transform: translateX(0); }
	25% {
		transform: translateX(-2px); }
	75% {
		transform: translateX(2px); } }

@keyframes bubbles-3 {
	0%,
	50%,
	100% {
		transform: translateX(0); }
	25% {
		transform: translateX(-3px); }
	75% {
		transform: translateX(3px); } }

@keyframes bubbles-4 {
	0%,
	50%,
	100% {
		transform: translateX(0); }
	25% {
		transform: translateX(3px); }
	75% {
		transform: translateX(-3px); } }

@media only screen and (max-width: 1020px) {
	.scheme {
		margin-left: -15px; } }
