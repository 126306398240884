/*== Magistral ==*/
@font-face {
    font-family: 'Magistral';
    src: url('../fonts/magistral/Magistral.ttf');
    font-weight: 300;
    font-style: normal; }

@font-face {
    font-family: 'Magistral';
    src: url('../fonts/magistral/Magistral-Bold.ttf');
    font-weight: 700;
    font-style: normal; }

/*== Exo ==*/
@font-face {
    font-family: 'Exo';
    src: url('../fonts/exo/Exo2.0.otf');
    font-weight: 300;
    font-style: normal; }

@font-face {
    font-family: 'Exo';
    src: url('../fonts/exo/Exo2.0-SemiBold.otf');
    font-weight: 500;
    font-style: normal; }

@font-face {
    font-family: 'Exo';
    src: url('../fonts/exo/Exo2.0-SemiBoldItalic.otf');
    font-weight: 500;
    font-style: italic; }
