.hythane {
	background-color: #E7F6FC;
	.container {
		background-color: #FBFBFB;
		&:not(.hythane__container) {
			background-color: #fbfbfb; } }
	&__container {
		background: {
			color: transparent !important;
			image: url('../img/hythane-bg.png');
			size: cover;
			position: center;
			repeat: no-repeat; } }
	&__inner {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 70px 0 55px 0; }
	&__col {
		&:first-child {
			flex-shrink: 0;
			max-width: 490px;
			width: 50%; }
		&:last-child {
			padding-top: 40px;
			padding-left: 115px; } }
	&__text {
		margin-bottom: 20px;
		line-height: 29px; }
	&__subheading {
		margin: 0 0 10px 0;
		line-height: 23px;
		font: {
			size: 16px;
			weight: 600; } }
	&__list {
		margin: 0;
		padding: 0;
		&-item {
			position: relative;
			display: block;
			margin-bottom: 10px;
			padding-left: 17px;
			line-height: 29px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 12px;
				left: 0;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: #3E90D8; } } }
	&__ballon {
		width: 291px;
		height: 578px;
		background: {
			image: url('../img/balloon.png');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__scheme {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		max-width: 1440px;
		margin: 0 auto;
		padding: 30px 50px 15px 50px;
		&-inner {
			width: calc(100% - 450px);
			svg {
				width: 100%; } }
		// &-image
 }		// 	width: calc(100% - 500px)
	&__info {
		flex-shrink: 0;
		width: 440px;
		padding: 30px 50px 15px 20px;
		// padding-left: 20px
		&-primary {
			position: relative;
			margin-bottom: 22px;
			padding: 20px;
			border-radius: 10px;
			background-color: #E4F5FD;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: -20px;
				top: calc(50% - 28px);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 28px 20px 28px 0;
				border-color: transparent #e4f5fd transparent transparent; }
			&-text {
				margin: 0;
				line-height: 21px;
				font-size: 14px;
				sup {
					line-height: 1; } } }
		&-sub {
			margin: 0;
			position: relative;
			padding-left: 20px;
			line-height: 22px;
			font-size: 14px;
			&:not(:last-child) {
				margin-bottom: 15px; }
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 2px;
				height: 35px;
				background-color: #3E90D8; } } } }

@media only screen and (max-width: 1020px) {
	.hythane {
		&__col {
			&:first-child {
				width: 100%;
				padding-top: 25px;
				padding-bottom: 30px; }
			&:last-child {
				display: none; } }
		&__text {
			line-height: 17px;
			font-size: 11px; }
		&__subheading {
			line-height: 16px;
			font-size: 11px; }
		&__list-item {
			line-height: 16px;
			font-size: 11px;
			&:before {
				top: 5px; } }
		&__scheme {
			flex-flow: column nowrap;
			padding-bottom: 15px;
			&-inner {
				width: 100%;
				margin-bottom: 20px;
				svg {
					height: auto; } } }
		&__info {
			width: 100%;
			padding: 0;
			&-primary {
				margin-bottom: 15px;
				padding: 13px 40px 10px 15px;
				&:before {
					top: -16px;
					left: calc(50% - 28px);
					border-width: 0 28px 16px 28px;
					border-color: transparent transparent #e4f5fd transparent; }
				&-text {
					line-height: 17px;
					font-size: 11px; } }
			&-sub {
				margin-bottom: 10px;
				padding-left: 13px;
				line-height: 17px;
				font-size: 11px;
				&:before {
					height: 25px; } } } } }
