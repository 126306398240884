.specs {
	position: relative;
	padding-top: 55px;
	padding-bottom: 70px;
	&__blot {
		position: absolute;
		z-index: 1;
		bottom: 0px;
		right: 0px;
		width: 672px;
		height: 644px;
		transform: translateX(215px);
		background: {
			image: url('../img/blot-2.svg');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__table {
		position: relative;
		z-index: 2;
		max-width: 1080px;
		width: 100%;
		margin: 0 auto;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, .2); }
	&__row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: stretch;
		align-items: stretch;
		&:nth-child(even) {
			background-color: #fff;
			.specs__cell:not(:last-child) {
				border-right: 2px solid #efefef; } }
		&:nth-child(odd) {
			background-color: #efefef;
			.specs__cell:not(:last-child) {
				border-right: 2px solid #fff; } } }
	&__cell {
		width: 120px;
		margin: 0;
		padding: 10px;
		color: $new-blue;
		font-size: 14px;
		&--heading {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
			flex-shrink: 0;
			width: 220px;
			margin: 0;
			padding: 10px;
			color: $dark-blue;
			text-transform: uppercase;
			line-height: 1.4em;
			p {
				margin: 0; }
			sub {
				margin-bottom: -8px;
				line-height: 1em; }
			span {
				text-transform: lowercase; } } }
	&__hint {
		position: relative;
		z-index: 2;
		margin-top: 35px 0 0 0;
		opacity: 0.8; } }

@media only screen and (max-width: 1020px) {
	.specs {
		padding-top: 15px;
		padding-bottom: 45px;
		&__blot {
			display: none; }
		&__wrap {
			width: calc(100% + 30px);
			margin-left: -15px;
			overflow-x: scroll; }
		&__table {
			width: 750px;
			padding: 0 15px;
			box-shadow: none; }
		&__cell {
			width: 95px;
			padding: 5px;
			font-size: 11px;
			&--heading {
				position: relative;
				width: 145px;
				line-height: 14px;
				font-size: 10px; } } } }
