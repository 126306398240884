.popup {
	z-index: 9999;
	position: fixed;
	display: none;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(33, 33, 33, .75);
	&[data-popup="mix"] {
		.popup__desc {
			font-size: 16px; } }
	&__window {
		position: relative;
		z-index: 2;
		width: 950px;
		padding: 30px 0 50px 50px;
		border-radius: 10px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
		background: {
			color: #fff;
			position: bottom right;
			repeat: no-repeat; }
		&--quadrocopter {
			padding-right: 450px;
			.popup__icon {
				&--1 {
					background-image: url('../img/icons/quadrocopter-1.svg'); }
				&--2 {
					background-image: url('../img/icons/quadrocopter-2.svg'); }
				&--3 {
					background-image: url('../img/icons/quadrocopter-3.svg'); }
				&--4 {
					background-image: url('../img/icons/quadrocopter-4.svg'); }
				&--5 {
					background-image: url('../img/icons/quadrocopter-5.svg'); }
				&--6 {
					background-image: url('../img/icons/quadrocopter-6.svg'); } } }
		&--car {
			padding-right: 300px;
			padding-bottom: 110px;
			.popup__icon {
				&--1 {
					background-image: url('../img/icons/car-1.svg'); }
				&--2 {
					background-image: url('../img/icons/car-2.svg'); }
				&--3 {
					background-image: url('../img/icons/car-3.svg'); }
				&--4 {
					background-image: url('../img/icons/car-4.svg'); }
				&--5 {
					background-image: url('../img/icons/car-5.svg'); } } }
		&--mix {
			padding-right: 110px; }
		&--reserve {
			min-height: 500px;
			padding-right: 350px; }
		&--autonomy {
			padding-right: 280px;
			background-position: 105% -50%; }
		&--industrial {
			padding-right: 270px; } }
	&__close {
		position: absolute;
		top: 50px;
		right: 50px;
		width: 35px;
		height: 35px;
		border-radius: 5px;
		cursor: pointer;
		background: {
			color: #F8F8F8;
			image: url('../img/icons/close.svg');
			position: center;
			repeat: no-repeat; } }
	&__heading {
		min-height: 72px;
		margin: 0 0 15px 0;
		text-transform: uppercase;
		line-height: 36px;
		color: $new-blue;
		font: {
			family: Magistral, sans-serif;
			size: 24px; } }
	&__desc {
		margin-bottom: 20px;
		color: $new-blue;
		font-size: 14px; }
	&__subheading {
		margin: 0 0 20px 0;
		color: $new-blue;
		font-size: 18px; }
	&__list-heading {
		margin: 0 0 15px 0;
		color: $new-blue;
		font-size: 14px; }
	&__list {
		margin: 0;
		padding: 0 110px 0 0;
		&-item {
			position: relative;
			display: block;
			margin-bottom: 13px;
			padding-left: 15px;
			color: $new-blue;
			font-size: 14px;
			&:before {
				content: '';
				position: relative;
				display: block;
				top: .8em;
				left: -15px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background-color: #88C9FD; } } }
	&__row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 15px; }
	&__icon {
		flex-shrink: 0;
		width: 35px;
		height: 35px;
		margin-right: 20px;
		background: {
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__text {
		margin: 0;
		color: $new-blue; }
	&__specification {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 35px;
		.popup__desc {
			width: 325px;
			font-size: 12px; } }
	&__formula {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: stretch;
		margin-right: 25px;
		&-item {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-end;
			align-items: center;
			&--centered {
				justify-content: center; } }
		&-icon {
			background: {
				size: contain;
				position: center;
				repeat: no-repeat; }
			&--methane {
				width: 60px;
				height: 70px;
				margin-bottom: 5px;
				background-image: url('../img/icons/methane.png'); }
			&--plus {
				width: 17px;
				height: 16px;
				margin: 0 12px;
				background-image: url('../img/icons/plus.svg'); }
			&--hydrogen {
				width: 50px;
				height: 40px;
				margin-bottom: 18px;
				background-image: url('../img/icons/hydrogen.png'); } }
		&-text {
			margin: 0;
			text-align: center;
			font-size: 12px; } }
	&__characteristics {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start;
		.popup__list {
			width: 340px;
			padding-right: 0;
			&-heading {
				font-size: 16px; } } }
	&__emissions {
		&-graphs {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: flex-start;
			width: 275px;
			padding-bottom: 10px;
			border-bottom: 1px solid #f4f4f4; }
		&-item {
			position: relative;
			width: 45px;
			height: 185px;
			background-color: #f2f2f2;
			border-radius: 30px; }
		&-percentage {
			margin: 0 auto;
			padding-top: 10px;
			text-align: center;
			font-size: 18px; }
		&-fill {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			border-radius: 30px;
			background-image: linear-gradient(8.14deg, #33B3EA 6.65%, #3692FE 100%);
			&--50 {
				height: 95px; }
			&--23 {
				height: 64px; }
			&--16 {
				height: 44px; }
			&--7 {
				height: 23px; } }
		&-desc {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			padding-top: 5px;
			width: calc(100% + 7px);
			&-item {
				width: 50px;
				text-align: center; }
			&-text {
				display: inline-block;
				opacity: .5;
				sub {
					font-size: 0.6em;
					// font-size: 0.74em
					vertical-align: baseline; } }
			&-icon {
				display: inline-block;
				width: 8px;
				height: 14px;
				margin-bottom: -2px;
				padding-left: 2px;
				background: {
					image: url('../img/icons/arrow.svg');
					size: contain;
					position: center;
					repeat: no-repeat; } } } } }

@media only screen and (max-width: 1020px) {
	.popup {
		&__close {
			top: 10px;
			right: 10px; }
		&__window {
			max-width: 400px;
			max-height: calc(100% - 30px);
			width: calc(100% - 20px);
			overflow-y: scroll;
			padding: 15px;
			background-image: none !important;
			background-color: #F0FBFF;
			&--mix {
				background-color: #fff; } }
		&__heading {
			min-height: 40px;
			margin-bottom: 10px;
			line-height: 1.4em;
			font-size: 14px; }
		&__subheading {
			margin-bottom: 10px;
			font-size: 12px; }
		&__row {
			margin-bottom: 10px; }
		&__icon {
			width: 20px;
			height: 20px;
			margin-right: 10px; }
		&__text {
			font-size: 12px; }
		&__list {
			padding-right: 0; }
		&__characteristics {
			flex-flow: column nowrap;
			&-col {
				width: 100%; } }
		&__emissions {
			width: 275px;
			margin: 0 auto; } } }
