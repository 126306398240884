.heading {
	width: 100%;
	margin: 0 auto 15px auto;
	&__inner {
		max-width: 750px;
		width: 100%;
		margin: 0;
		line-height: 49px;
		color: $dark-blue;
		font: {
			family: Magistral, sans-serif;
			size: 36px; } } }

@media only screen and (max-width: 1020px) {
	.heading {
		margin: 0 auto 10px auto;
		&__inner {
			width: 255px;
			line-height: 27px;
			font-size: 18px; } } }
