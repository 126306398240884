.certificates {
	display: none;
	padding: 50px 0 40px 0;
	background-color: #F0FBFF;
	&__row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start; }
	&__item {
		position: relative;
		width: 220px;
		height: 310px;
		margin-bottom: 15px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
		background: {
			size: contain;
			position: center;
			repeat: no-repeat; }
		&:hover {
			.certificates__bg {
				opacity: 0.35; }
			.certificates__btn {
				opacity: 1; } } }
	&__bg {
		z-index: 1;
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #212121;
		transition: all .3s ease-in-out; }
	&__btn {
		z-index: 2;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 135px;
		padding: 10px;
		text-align: center;
		color: #fff;
		transform: translate(-50%, -50%);
		text-decoration: none;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
		background-image: linear-gradient(90deg, #3692FE 0%, #24CCE3 50%, #3692FE 100%);
		background-size: 450px 1px;
		font-size: 16px;
		transition: all .3s ease-in-out;
		&:hover {
			background-position: 225px; } } }

@media only screen and (max-width: 1020px) {
	.certificates {
		padding: 25px 0 50px 0;
		&__grid {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			overflow-x: scroll; }
		&__item {
			width: 100px;
			height: 140px;
			margin-right: 12px; }
		&__btn {
			width: 85px;
			padding: 5px;
			font-size: 11px; } } }
