.header {
	position: fixed;
	z-index: 9999;
	padding: 30px 0;
	transition: all .3s ease-in-out;
	&--white {
		padding: 5px 0;
		background-color: #fff;
		box-shadow: 0 10px 40px 0 rgba(0,0,0,.15);
		.header {
			&__hamburger {
				background-color: transparent;
				// background-color: #0070B8
				&-item {
					background-color: #0e6abf; } } } }
	&--alt {
		&:not(.header--white) {
			.header {
				&__hamburger {
					&-item {
						background-color: #fff; } }
				&__logo {
					background-image: url('../img/logo-white.svg'); }
				&__link {
					color: #fff;
					&:hover {
						color: #fff; } }
				&__lang {
					&-item {
						color: #fff;
						&:not(:last-child) {
							border-right-color: #fff; } } } } } }
	&--opened {
		&.header--white {
			background-color: #0070B8;
			.header {
				&__logo {
					background-image: url('../img/logo-white.svg'); } } }
		.header {
			&__lang {
				opacity: 0;
				visibility: hidden; } } }
	&__inner {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		max-width: 1920px; }
	&__logo {
		width: 180px;
		height: 55px;
		background: {
			image: url('../img/logo.svg');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__link {
		position: relative;
		margin: 0 15px;
		padding: 6px 0;
		color: $dark-blue;
		text-decoration: none;
		transition: all .3s ease-in-out;
		&:hover {
			color: $blue; }
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 1px;
			background-color: $dark-blue;
			transition: all 1s ease-in-out; }
		&--active {
			pointer-events: none;
			&:after {
				width: 100%; } } }

	&__lang {
		text-transform: uppercase;
		&--mobile {
			display: none; }
		&-item {
			padding: 0 5px;
			color: $dark-blue;
			text-decoration: none;
			opacity: .5;
			transition: all .3s ease-in-out;
			&:hover {
				opacity: 1;
				color: $blue; }
			&:not(:last-child) {
				border-right: 1px solid $dark-blue; }
			&--active {
				pointer-events: none;
				opacity: 1; } } }
	&__actions {
		position: relative;
		z-index: 99999;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center; }
	&__hamburger {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
		width: 40px;
		height: 20px;
		margin-right: 35px;
		cursor: pointer;
		// border-radius: 50%
		// background-color: #009AD7
		&--active {
			height: 30px;
			.header__hamburger-item {
				background-color: #fff;
				&:first-child {
					transform: rotate(45deg); }
				&:nth-child(2) {
					opacity: 0; }
				&:last-child {
					transform: rotate(-45deg); } } }
		&-item {
			transform-origin: left;
			width: 40px;
			height: 2px;
			// margin: 1.5px 0
			// border-radius: 1px
			background-color: #0e6abf;
			transition: all .3s ease-in-out; } }
	// &__hamburger,
	// &__empty
	// 	display: none
	&__list {
		position: absolute;
		top: 0;
		right: -390px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		width: 390px;
		height: 100vh;
		padding: 115px 20px 30px 80px;
		background-color: #0070B8;
		transition: all .5s ease-in-out;
		&--opened {
			right: 0; }
		&-arrow {
			position: absolute;
			top: 12px;
			right: -20px;
			width: 10px;
			height: 5px;
			transform: rotate(180deg);
			transition: all .3s ease-in-out;
			background: {
				image: url('../img/icons/arrow-white.svg');
				size: cover;
				position: center;
				repeat: no-repeat; }
			&--active {
				transform: rotate(0); } }
		&-item {
			position: relative;
			margin-bottom: 20px;
			color: #fff;
			text-decoration: none;
			line-height: 26px;
			font-size: 18px; }
		&-dropdown {
			margin-bottom: 20px; }
		&-links {
			display: none;
			// display: flex
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			padding-top: 10px;
			padding-bottom: 10px;
			&-item {
				display: block;
				margin: 10px 0;
				color: #fff;
				text-decoration: none;
				text-transform: uppercase;
				font: {
					size: 14px;
					weight: 300; } } } }
	&__out {
		margin-top: auto;
		color: #fff;
		opacity: 0.25;
		text-decoration: none;
		letter-spacing: 1px;
		font: {
			family: 'Magistral';
			size: 14px;
			weight: 700; }
		transition: all .3s ease-in-out;
		&:hover {
			opacity: 1; } } }
@media only screen and (max-width: 1020px) {
	.header {
		padding: 15px 0;
		&--opened {
			background-color: transparent !important; }
		&__list {
			right: auto;
			left: -230px;
			width: 230px;
			padding: 80px 20px 20px 15px;
			&--opened {
				left: 0; }
			&-item {
				margin-bottom: 10px;
				line-height: 16px;
				font-size: 12px; }
			&-dropdown {
				margin-bottom: 10px; }
			&-arrow {
				top: 6px; }
			&-links {
				padding-bottom: 0;
				&-item {
					margin: 0 0 10px 0;
					font-size: 10px; } } }
		&--white {
			padding: 5px 0;
			.header {
				&__hamburger {
					background-color: #0e6abf;
					&-item {
						background-color: #fff; } } } }
		&--alt {
			&:not(.header--white) {
				.header {
					&__hamburger {
						background-color: #fff;
						&-item {
							background-color: #0070B8; } }
					&__logo {
						background-image: url('../img/logo-small-white.svg'); } } } }
		&--opened {
			background-color: #fff;
			.header {
				&__hamburger {
					background-color: #fff !important;
					&-item {
						background-color: #0070B8 !important; } }
				&__logo {
					background-image: url('../img/logo-small.svg') !important; }
				&__hamburger {
					&-item {
						&:first-child {
							transform: translateX(1px) rotate(45deg); }
						&:nth-child(2) {
							opacity: 0; }
						&:last-child {
							transform: translateX(1px) rotate(-45deg); } } }
				&__link {
					color: #0e6abf !important; } } }
		&__inner {
			flex-wrap: wrap; }
		&__logo {
			order: 2;
			width: 25px;
			height: 50px;
			background-image: url('../img/logo-small.svg');
			transform: translateX(10px); }
		&__nav {
			display: none;
			flex-flow: column nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			padding: 5px 0; }
		&__link {
			display: block; }
		&__lang {
			display: none;
			&--mobile {
				order: 3;
				display: block; }
			&-item {
				font-size: 12px; } }
		&__hamburger {
			// display: flex
			// flex-flow: column nowrap
			justify-content: center;
			// align-items: center
			width: 30px;
			height: 30px;
			border-radius: 50%;
			// background-color: #009AD7
			&-item {
				width: 12px;
				height: 1px;
				margin: 1.5px 0;
				// border-radius: 1px
				// background-color: #fff
 } }				// transition: all .3s ease-in-out
		&__empty {
			display: block;
			width: 30px; }
		&__hamburger {
			order: 1;
			margin-right: 0; } } }
