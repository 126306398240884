.calculator {
	width: 640px;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
	&__eval {
		padding: 20px 30px 25px 25px;
		background-color: #fff; }
	&__row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px; }
	&__text {
		margin: 0;
		color: $new-blue;
		font-size: 21px; }
	&__label {
		overflow: hidden;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: stretch;
		width: 245px;
		border: 1px solid #AFC4D5;
		border-radius: 5px;
		&-input {
			-moz-appearance: textfield;
			width: 195px;
			padding: 9px 15px;
			outline: none;
			border: none;
			border-radius: 0;
			font: {
				family: Exo, sans-serif;
				size: 18px; }
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0; } }
		&-text {
			width: 50px;
			margin: 0;
			padding: 10px 0;
			color: $new-blue;
			text-align: center;
			border-left: 1px solid #AFC4D5;
			background-color: #EEF2F5; } }
	&__btn {
		display: block;
		width: 225px;
		margin-top: 35px;
		padding: 12px 0 15px 0;
		text-align: center;
		color: #fff;
		text-decoration: none;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
		background-image: linear-gradient(90deg, #3692FE 0%, #24CCE3 50%, #3692FE 100%);
		background-size: 450px 1px;
		font-size: 20px;
		transition: all .3s ease-in-out;
		&:hover {
			background-position: 225px; } }
	&__sum {
		padding: 20px 40px;
		background-color: #f7f7f7;
		color: $new-blue;
		&-heading {
			margin: 0 0 5px 0;
			font-size: 18px; }
		&-result {
			margin: 0;
			font-size: 36px; } } }

@media only screen and (max-width: 1020px) {
	.calculator {
		width: 100%;
		&__eval {
			padding: 12px 24px 20px 10px; }
		&__row {
			margin-bottom: 18px; }
		&__text {
			width: 100px;
			font-size: 12px; }
		&__label {
			width: 120px;
			&-input {
				width: 88px;
				padding: 5px 8px;
				font-size: 12px; }
			&-text {
				width: 32px;
				padding: 5px;
				font-size: 11px; } }
		&__btn {
			width: 110px;
			margin-top: 30px;
			padding: 8px;
			font-size: 12px; }
		&__sum {
			padding: 12px 10px;
			&-heading {
				margin-bottom: 5px;
				font-size: 12px; }
			&-result {
				font-size: 12px; } } } }
