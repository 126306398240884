.preloader {
	z-index: 99999;
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	&__logo {
		svg {
			width: 561px;
			height: 174px; } }
	.logo-additionals > *:nth-child(3n) {
		animation: flickerAnimation 1.5s linear 0s infinite; }
	.logo-additionals > *:nth-child(3n+1) {
    	animation: flickerAnimation 1.5s linear .7s infinite; }
	.logo-additionals > *:nth-child(3n+2) {
    	animation: flickerAnimation 1.5s linear .4s infinite; } }

@keyframes flickerAnimation {
	0% {
		opacity: 1; }
	50% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@media only screen and (max-width: 1020px) {
	.preloader {
		&__logo {
			svg {
				max-width: calc(100% - 30px);
				margin-left: 15px; } } } }
