.usage {
	overflow: hidden;
	padding: 65px 0 50px 0;
	background: {
		image: url('../img/usage_bg.png'), linear-gradient(-90deg, #1092C5 0%, #1178D8 100%);
		size: cover;
		position: center;
		repeat: no-repeat; }
	&__inner {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center; }
	&__molecule {
		position: absolute;
		animation: {
			name: molecule;
			timing-function: linear;
			iteration-count: infinite;
			duration: 10s; }
		background: {
			image: url('../img/water-1.png');
			size: contain;
			position: center;
			repeat: no-repeat; }
		&--1 {
			bottom: -10px;
			left: -190px;
			width: 300px;
			height: 225px;
			animation-duration: 10s; }
		&--2 {
			top: 15px;
			left: calc(50% - 200px);
			width: 320px;
			height: 240px;
			animation-duration: 8s; }
		&--3 {
			top: -30px;
			right: -300px;
			width: 450px;
			height: 350px;
			animation-duration: 14s; } }
	&__heading {
		position: relative;
		width: 280px;
		color: #fff;
		text-transform: uppercase;
		line-height: 54px;
		font: {
			family: Magistral, sans-serif;
			size: 36px; }
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 10px;
			right: -40px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 15px 0 15px 30px;
			border-color: transparent transparent transparent #27B8F2; } }
	&__grid {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start;
		width: 580px; }
	&__col {
		width: 275px; }
	&__round {
		position: relative;
		z-index: 2;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		width: 75px;
		height: 75px;
		margin: 0 auto -15px auto;
		border-radius: 50%;
		background-image: linear-gradient(90deg, #3692FE 0%, #61DFF1 100%); }
	&__item {
		position: relative;
		display: block;
		margin-bottom: 15px;
		text-decoration: none;
		&:hover {
			.usage__block:after {
				opacity: 1; } } }
	&__block {
		position: relative;
		overflow: hidden;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		width: 275px;
		height: 115px;
		border-radius: 10px;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
		&:after {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
			bottom: 0;
			left: calc(50% - 75px);
			width: 150px;
			height: 3px;
			opacity: 0;
			background-color: #fff;
			transition: all .3s ease-in-out; }
		&-bg {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: .7;
			border-radius: 10px;
			background-image: linear-gradient(90deg, #3692FE 0%, #61DFF1 100%); }
		&-text {
			position: relative;
			z-index: 2;
			margin: 0;
			padding: 0 10px;
			text-transform: uppercase;
			color: #fff;
			text-align: center;
			font-size: 14px; }
		&-pulse {
			z-index: 2;
			position: absolute;
			bottom: 7px;
			left: 50%;
			width: 20px;
			height: 20px;
			transform: translateX(-50%);
			opacity: 0.6;
			cursor: pointer;
			&-item {
				animation: {
					name: pulse;
					duration: 2s;
					timing-function: linear;
					iteration-count: infinite;
					direction: normal;
					fill-mode: forwards; }
				background-color: #fff;
				border-radius: 50%;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				&:nth-child(1) {
					animation-delay: 0s; }
				&:nth-child(2) {
					animation-delay: 1s; }
				&:nth-child(3) {
					animation-delay: 1.5s; } } } } }

@keyframes molecule {
	0%,
	100% {
		transform: translateX(0); }
	50% {
		transform: translateX(15%); } }

@keyframes pulse {
	0% {
		opacity: 0;
		transform: scale(0.1, 0.1); }
	50% {
		opacity: 1; }
	100% {
		opacity: 0;
		transform: scale(1.2, 1.2); } }

@media only screen and (max-width: 1020px) {
	.usage {
		padding-top: 40px;
		padding-bottom: 30px;
		&__molecule {
			display: none; }
		&__inner {
			display: block; }
		&__heading {
			width: 140px;
			margin: 0 0 15px 0;
			line-height: 27px;
			font-size: 18px;
			&:after {
				display: none; } }
		&__grid {
			width: 100%; }
		&__col {
			width: calc(50% - 9px); }
		&__item {
			margin-bottom: 20px; }
		&__round {
			width: 37px;
			height: 37px;
			margin: 0 auto -10px auto;
			svg {
				width: 60%;
				height: auto; } }
		&__block {
			width: 100%;
			height: 65px;
			padding: 5px;
			padding-bottom: 15px;
			&-text {
				padding: 0;
				line-height: 9px;
				font-size: 9px; }
			&-pulse {
				bottom: 10px;
				width: 10px;
				height: 10px; } } } }
