.footer {
	position: relative;
	height: 500px;
	&--mobile {
		display: none; }
	&--inner {
		height: auto;
		padding: 35px 0;
		background-color: #436C9A;
		.footer {
			&__wrapper {
				max-width: 1440px;
				width: 100%;
				margin: 0 auto;
				// padding: 0 100px
				padding: 0 260px 0 100px; }
			&__grid {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: stretch; }
			&__col {
				position: relative;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: flex-start;
				width: 25%;
				height: 75px;
				&:not(:last-child) {
					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						width: 1px;
						height: 100%;
						background-color: #fff;
						opacity: 0.25; } }
				&:not(:first-child) {
					padding-left: 20px; } }
			&__text {
				margin: 5px 0;
				color: #fff;
				font: {
					size: 14px;
					weight: 100; } }
			&__copyright {
				margin: 0;
				opacity: 0.75;
				color: #fff;
				font: {
					size: 14px;
					weight: 300; } }
			&__item {
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center; }
			&__contacts-icon {
				width: 20px;
				height: 20px;
				margin-right: 10px; } } }
	&__map {
		max-height: 500px;
		height: 500px;
		filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		filter: gray;
		[class$='controls-pane'],
		[class$='copyrights-pane'] {
			display: none !important; } }
	&__contacts {
		position: absolute;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: flex-start;
		top: 0;
		left: 0;
		width: calc(50% - 125px);
		height: 500px;
		background-color: transparentize(#436C9A, .1);
		color: #fff;
		&-inner {
			width: 470px;
			padding: 70px 20px 0 20px; }
		&-heading {
			margin: 0 0 50px 0;
			text-transform: uppercase;
			letter-spacing: 1px;
			font: {
				size: 40px;
				weight: 500; } }
		&-copy {
			margin: 0 0 30px 0;
			opacity: 0.75;
			font-size: 14px; }
		&-row {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 30px; }
		&-icon {
			width: 30px;
			height: 30px;
			margin-right: 20px;
			background: {
				size: contain;
				position: center;
				repeat: no-repeat; }
			&--address {
				background-image: url('../img/icons/map.svg'); }
			&--tel {
				background-image: url('../img/icons/tel.svg'); }
			&--mail {
				background-image: url('../img/icons/mail.svg'); } }
		&-text {
			margin: 0;
			font-size: 20px; } } }

@media only screen and (max-width: 1020px) {
	.footer {
		height: auto;
		&--inner {
			display: none; }
		&--mobile {
			position: relative;
			display: block; }
		&__map {
			display: none; }
		&__contacts {
			width: 100%;
			height: auto;
			padding: 10px 0;
			&-heading {
				display: none; }
			&-inner {
				max-width: 320px;
				width: 100%;
				margin: 0 auto;
				padding: 0 15px; }
			&-row {
				margin-bottom: 10px; }
			&-icon {
				flex-shrink: 0;
				width: 12px;
				height: 12px;
				margin-right: 12px; }
			&-text {
				font-size: 10px; }
			&-copy {
				width: 100%;
				margin-bottom: 0;
				opacity: 0.3;
				color: #212121;
				text-align: center;
				font-size: 9px; } } } }
