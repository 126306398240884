.fuel {
	.container:not(.scheme-fuel) {
		padding-top: 45px;
		padding-bottom: 75px;
		background: {
			image: url('../img/fuel_bg.png'), linear-gradient(0deg, #F0FBFF, #F0FBFF), linear-gradient(2.75deg, rgba(12, 165, 226, 0.8) 3.45%, #0E6ABF 182.08%);
			position: bottom right;
			repeat: no-repeat; } }
	.scheme-fuel .wrapper {
		width: 100%;
		position: relative;
		max-width: 1780px;
		overflow-y: hidden !important;
		.scheme {
			width: 1700px; } }
	&__inner {
		max-width: 1080px;
		width: 100%;
		margin: 0 auto; }
	&__desc {
		width: 550px;
		margin: 0 0 35px 0;
		// opacity: .7
		color: $new-blue;
		font-size: 18px; }
	&__calc {
		margin-top: 0;
		margin-bottom: 20px;
		text-transform: uppercase;
		color: $new-blue;
		font: {
			size: 20px;
			weight: 500; }
		span {
			text-transform: lowercase; } }
	&__scroll {
		z-index: 2;
		position: absolute;
		top: calc(50% - 50px);
		right: 0;
		width: 100px;
		height: 100px;
		opacity: 0.95;
		background-image: linear-gradient(270deg, #D5F2FF 0%, #BBE4F7 100%);
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: calc(50% - 23px);
			width: 20px;
			height: 46px;
			background: {
				image: url('../img/icons/icon-arrow.svg');
				size: contain;
				position: center;
				repeat: no-repeat; } }
		&:after {
			right: 32px; }
		&:before {
			right: 45px; } }
	.ps__rail-y {
		display: none; } }
@media only screen and (min-width: 1780px) {
	.fuel__scroll {
		display: none; } }

@media only screen and (max-width: 1020px) {
	.fuel {
		.container:not(.scheme-fuel) {
			padding-top: 35px;
			background-image: linear-gradient(0deg, #F0FBFF, #F0FBFF); }
		&__desc {
			width: 100%;
			margin-bottom: 10px;
			font-size: 10px; }
		&__calc {
			margin: 0 0 15px 0;
			font-size: 12px; }
		&__scroll {
			display: none; } } }
