.redirect {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: stretch;
	&__col {
		position: relative;
		width: 50%;
		// &:hover
		// 	.redirect__bg-inner
		// 		opacity: 0.9
		&:first-child {
			.redirect__content {
				margin-left: auto; } } }
	&__content {
		z-index: 2;
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: flex-start;
		height: 100%;
		padding: 145px 250px 120px 70px;
		max-width: 720px;
		color: #fff; }
	&__ref {}
	&__heading {
		margin: 0 0 15px 0;
		text-transform: uppercase;
		font: {
			size: 30px;
			family: 'Magistral'; } }
	&__desc {
		margin: 0 0 20px 0;
		line-height: 29px;
		font-size: 16px; }
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $dark-blue;
		&-inner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.7;
			transition: all .5s ease-in-out;
			background: {
				size: cover;
				position: top right;
				repeat: no-repeat; } } }
	&__molecule {
		position: absolute;
		bottom: 60px;
		right: 20px;
		&--hydrogen {
			width: 222px;
			height: 284px;
			background: {
				image: url('../img/hydrogen.png');
				size: contain;
				position: center;
				repeat: no-repeat; } }
		&--methane {
			width: 249px;
			height: 308px;
			background: {
				image: url('../img/methane.png');
				size: contain;
				position: center;
				repeat: no-repeat; } } }
	&__btn {
		display: block;
		width: 210px;
		padding: 15px;
		border: 2px solid #fff;
		color: #fff;
		text-align: center;
		text-decoration: none;
		transition: all .3s ease-in-out;
		font-size: 18px;
		&:hover {
			background-color: #fff;
			color: $dark-blue; } } }

@media only screen and (max-width: 1020px) {
	.redirect {
		flex-flow: column nowrap;
		&__col {
			width: 100%; }
		&__content {
			width: 320px;
			margin: 0 auto;
			padding: 25px 15px 18px 15px; }
		&__heading {
			margin-bottom: 7px;
			line-height: 25px;
			font-size: 18px; }
		&__desc {
			margin-bottom: 10px;
			padding-right: 30px;
			line-height: 16px;
			font-size: 11px; }
		&__btn {
			width: 125px;
			padding: 10px;
			font-size: 12px; }
		&__ref {
			z-index: 2; }
		&__molecule {
			z-index: 1;
			right: 0;
			bottom: 30px;
			&--hydrogen {
				width: 85px;
				height: 110px; }
			&--methane {
				width: 90px;
				height: 110px; } } } }
