.electricity {
	.container:not(.scheme-electricity) {
		padding-top: 50px;
		padding-bottom: 35px;
		background-color: #F0FBFF; }
	.scheme-electricity .wrapper {
		max-width: 2109px;
		width: 100%;
		position: relative;
		overflow-y: hidden !important; }
	&__content {
		width: 1080px;
		margin: 0 auto; }
	&__desc {
		margin-bottom: 15px;
		color: $new-blue;
		font-size: 18px; }
	&__list {
		width: 650px;
		margin: 0 0 30px 0;
		padding: 0;
		&-item {
			position: relative;
			display: block;
			margin-bottom: 15px;
			padding-left: 30px;
			color: $new-blue;
			line-height: 21px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 20px;
				height: 20px;
				background: {
					image: url('../img/icons/check.svg');
					size: contain;
					position: center;
					repeat: no-repeat; } } } }
	&__table {
		position: relative;
		z-index: 2;
		width: 100%;
		margin: 0 auto;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, .2); }
	&__row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: stretch;
		align-items: stretch;
		&:nth-child(even) {
			background-color: #efefef;
			.electricity__cell:not(:last-child) {
				border-right: 2px solid #fff; } }
		&:nth-child(odd) {
			background-color: #fff;
			.electricity__cell:not(:last-child) {
				border-right: 2px solid #efefef; } }
		.electricity__cell {
			flex-shrink: 1;
			width: 185px;
			color: $new-blue;
			&:not(:first-child) {
				flex-grow: 0;
				flex-shrink: 0; } } }
	&__cell {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin: 0;
		padding: 15px 5px;
		color: $deep-blue;
		font-size: 14px;
		&--empty {
			justify-content: center;
			font-size: 18px; }
		&--heading {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;
			flex-shrink: 0;
			width: 190px;
			margin: 0;
			color: $dark-blue;
			text-transform: uppercase;
			line-height: 23px;
			font-size: 14px;
			span {
				text-transform: lowercase; }
			sub {
				margin-top: 10px;
				font-size: 12px; } } }
	&__hint {
		margin-top: 35px 0 0 0;
		opacity: 0.8; }
	&__scroll {
		z-index: 2;
		position: absolute;
		top: calc(50% - 50px);
		right: 0;
		width: 100px;
		height: 100px;
		opacity: 0.95;
		background-image: linear-gradient(270deg, #D5F2FF 0%, #BBE4F7 100%);
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: calc(50% - 23px);
			width: 20px;
			height: 46px;
			background: {
				image: url('../img/icons/icon-arrow.svg');
				size: contain;
				position: center;
				repeat: no-repeat; } }
		&:after {
			right: 32px; }
		&:before {
			right: 45px; } }
	.ps__rail-y {
		display: none; } }

@media only screen and (min-width: 2109px) {
	.electricity__scroll {
		display: none; } }

@media only screen and (max-width: 1020px) {
	.electricity {
		.container:not(.scheme-electricity) {
			padding-top: 30px; }
		&__content {
			width: 100%; }
		&__desc {
			line-height: 16px;
			font-size: 11px; }
		&__list {
			width: 100%;
			margin-bottom: 10px;
			&-item {
				margin-bottom: 10px;
				padding-left: 20px;
				line-height: 16px;
				font-size: 11px;
				&:before {
					top: 2px;
					width: 12px;
					height: 12px; } } }
		&__wrap {
			width: calc(100% + 30px);
			margin-left: -15px;
			overflow-x: scroll; }
		&__table {
			width: 700px;
			padding: 0 15px;
			box-shadow: none; }
		&__cell {
			width: 140px !important;
			padding: 5px;
			font-size: 11px;
			&--heading {
				width: 140px;
				line-height: 1.4em;
				font-size: 10px; } }
		&__hint {
			margin-top: 15px;
			font-size: 9px; }
		&__scroll {
			display: none; } } }
