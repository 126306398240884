.hero {
	z-index: 1;
	position: relative;
	overflow: hidden;
	// height: 700px
	height: 100vh;
	&__inner {
		position: relative;
		z-index: 3;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-end;
		height: 100%;
		&--hydrogen,
		&--hythane {
			position: static;
			align-items: center;
			.hero__heading {
				line-height: 1.4em; }
			.hero__gradient {
				position: absolute;
				z-index: 2;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border-radius: 0; } }
		&--hydrogen {
			.hero__gradient {
				opacity: 0.5;
				background-image: linear-gradient(180deg, #0E6ABF 0%, #228CC9 100%); } }
		&--hythane {
			.hero__gradient {
				opacity: 1;
				background: {
					image: url('../img/hythane-hero-bg.jpg');
					size: cover;
					position: center bottom;
					repeat: no-repeat; } } } }
	// &__dots
	// 	position: absolute
	// 	z-index: 2
	// 	top: 0
	// 	right: 0
	// 	width: 100%
	// 	height: 100%
	// 	background:
	// 		image: url('../img/dots-bg.png')
	// 		size: cover
	// 		position: center
	// 		repeat: no-repeat
	&__hydrogen {
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(50%, -50%);
		width: 327px;
		height: 419px;
		background: {
			image: url('../img/hydrogen-big.png');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__methane {
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(50%, -50%);
		width: 447px;
		height: 553px;
		background: {
			image: url('../img/methane-big.png');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__video {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%; }
	&__molecule {
		position: absolute;
		z-index: 2;
		background: {
			size: contain;
			position: center;
			repeat: no-repeat; }
		animation: {
			timing-function: linear;
			iteration-count: infinite; }
		&--big {
			background-image: url('../img/water-2.png');
			animation-duration: 10s; }
		&--small {
			animation-duration: 8s;
			background-image: url('../img/water-1.png'); }
		&--1 {
			bottom: 90px;
			right: 0;
			width: 280px;
			height: 350px;
			animation-name: molecule-big-1; }
		&--2 {
			bottom: 0px;
			left: -135px;
			width: 180px;
			height: 220px;
			transform: rotate(-36deg);
			animation-delay: 2s;
			animation-name: molecule-big-2; }
		&--3 {
			top: 15%;
			left: -150px;
			width: 230px;
			height: 170px;
			animation-name: molecule-small-1; }
		&--4 {
			top: 46%;
			right: 47.92%;
			width: 130px;
			height: 100px;
			transform: rotate(-67deg);
			animation-delay: 2.5s;
			animation-name: molecule-small-2; }
		&--5 {
			top: 6.5%;
			left: 41.6%;
			opacity: 0.3;
			width: 175px;
			height: 135px;
			transform: rotate(11deg);
			animation-delay: 2s;
			animation-name: molecule-small-3; }
		&--6 {
			bottom: 32%;
			right: 41.6%;
			opacity: 0.75;
			width: 145px;
			height: 112px;
			transform: rotate(-105deg);
			animation-delay: 1.5s;
			animation-name: molecule-small-4; }
		&--7 {
			bottom: 20%;
			left: 18.5%;
			opacity: 0.3;
			width: 110px;
			height: 85px;
			transform: rotate(45deg);
			animation-name: molecule-small-5; }
		&--8 {
			top: 19%;
			left: 14%;
			opacity: 0.3;
			width: 92px;
			height: 70px;
			transform: rotate(-24deg);
			animation-name: molecule-small-6; }
		&--9 {
			bottom: 38%;
			left: 6%;
			opacity: 0.3;
			width: 67px;
			height: 51px;
			transform: rotate(15deg);
			animation-name: molecule-small-7; } }
	&__block {
		position: relative;
		z-index: 3;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		width: 700px;
		height: 325px;
		margin-bottom: 15%;
		padding: 45px; }
	&__bubble {
		position: absolute;
		border-radius: 50%;
		background-image: linear-gradient(253.26deg, #0E6ABF 1.41%, #25A2D2 91.27%);
		&--1 {
			top: -20px;
			right: -45px;
			width: 34px;
			height: 34px; }
		&--2 {
			top: 16px;
			right: -68px;
			width: 10px;
			height: 10px; }
		&--3 {
			top: 32px;
			right: -90px;
			width: 18px;
			height: 18px; }
		&--4 {
			top: 95px;
			right: -156px;
			width: 14px;
			height: 14px; } }
	&__gradient {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		opacity: .75;
		background-image: linear-gradient(238.81deg, #0E6ABF 1.41%, #25A2D2 91.27%); }
	&__heading {
		position: relative;
		z-index: 2;
		margin: 0;
		color: #fff;
		line-height: 71px;
		text-transform: uppercase;
		font: {
			family: Magistral, sans-serif;
			size: 40px; } }
	.scroll-cta {
		z-index: 2;
		position: absolute;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		&--white {
			.scroll-cta {
				&__text {
					color: #fff; }
				&__icon {
					background-image: url('../img/icons/arrow-circle-white.svg'); } } }
		&__text {
			color: $dark-blue;
			text-transform: uppercase;
			line-height: 17px;
			font-size: 12px; }
		&__icon {
			width: 30px;
			height: 30px;
			margin: 0 auto;
			background: {
				image: url('../img/icons/arrow-circle.svg');
				size: contain;
				position: center;
				repeat: no-repeat; } } } }

@keyframes molecule-big-1 {
	0%,
	100% {
		transform: translateX(0); }
	50% {
		transform: translateX(10%); } }

@keyframes molecule-big-2 {
	0%,
	100% {
		transform: translateX(0) rotate(-36deg); }
	50% {
		transform: translateX(10%) rotate(-36deg); } }

@keyframes molecule-small-1 {
	0%,
	100% {
		transform: translateX(0); }
	50% {
		transform: translateX(15%); } }

@keyframes molecule-small-2 {
	0%,
	100% {
		transform: translateX(0) rotate(-67deg); }
	50% {
		transform: translateX(15%) rotate(-67deg); } }

@keyframes molecule-small-3 {
	0%,
	100% {
		transform: translateX(0) rotate(11deg); }
	50% {
		transform: translateX(-13%) rotate(11deg); } }

@keyframes molecule-small-4 {
	0%,
	100% {
		transform: translateX(0) rotate(-105deg); }
	50% {
		transform: translateX(17%) rotate(-105deg); } }

@keyframes molecule-small-5 {
	0%,
	100% {
		transform: translateX(0) rotate(45deg); }
	50% {
		transform: translateX(20%) rotate(45deg); } }

@keyframes molecule-small-6 {
	0%,
	100% {
		transform: translateX(0) rotate(-24deg); }
	50% {
		transform: translateX(10%) rotate(-24deg); } }

@keyframes molecule-small-7 {
	0%,
	100% {
		transform: translateX(0) rotate(15deg); }
	50% {
		transform: translateX(-15%) rotate(15deg); } }

@media only screen and (max-width: 1020px) {
	.hero {
		height: 300px;
		background: {
			image: url('../img/hero.jpg');
			size: cover;
			position: center bottom;
			repeat: no-repeat; }
		&__inner {
			min-height: 0;
			height: 300px; }
		&__video {
			display: none; }
		&__molecule {
			&--1 {
				width: 60px;
				height: 95px; }
			&--2 {
				display: none; }
			&--3 {
				top: 65%;
				left: -50px;
				width: 86px;
				height: 66px; }
			&--4 {
				top: 71%;
				right: 28%;
				width: 90px;
				height: 70px; } }
		&__bubble {
			display: none; }
		&__block {
			width: 100%;
			height: 100px;
			margin-bottom: 50px;
			padding: 20px 5px 20px 10px; }
		&__heading {
			line-height: 27px;
			font-size: 18px; }
		&__hydrogen {
			width: 120px;
			height: 90px; }
		.scroll-cta {
			display: none; } } }
