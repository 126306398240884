.car {
	&__desc {
		padding: 42px 0; }
	&__graph {

		padding: 50px;
		background-color: #F5FAFF; }
	&__wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: flex-start;
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
		padding: 0 100px; }
	&__col {
		width: calc(50% - 40px); }
	&__text {
		// width: 400px
		padding-right: 30px;
		line-height: 29px;
		font-weight: 300;
		&:not(:last-child) {
			margin-bottom: 20px; } }
	&__subheading {
		width: 430px;
		margin: 0;
		line-height: 25px;
		color: #0070B8;
		font-size: 18px; }
	&__chart {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: flex-start;
		margin-bottom: 20px; }
	&__park {
		margin: 0; }
	&__stat {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		max-width: 490px;
		width: 100%;
		margin-left: -5px; }
	&__icon {
		width: 39px;
		height: 39px;
		margin: 0 5px;
		background: {
			size: contain;
			position: center;
			repeat: no-repeat; }
		&--full {
			background-image: url('../img/icons/car-full.svg'); }
		&--half {
			background-image: url('../img/icons/car-half.svg'); }
		&--empty {
			background-image: url('../img/icons/car-empty.svg'); } }
	&__ratio {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 40px;
		&-text {
			position: relative;
			width: 240px;
			margin: 0 35px 0 0;
			padding: 14px 7px 8px 18px;
			background-color: #DEF4FF;
			border-radius: 10px;
			font-size: 14px;
			&:before {
				content: '';
				display: block;
				position: absolute;
				right: -17px;
				top: calc(50% - 17px);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 17px 0 17px 17px;
				border-color: transparent transparent transparent #DEF4FF; } }
		&-clouds {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center; } }
	&__cloud {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		width: 75px;
		height: 50px;
		margin: 0;
		padding: 10px 0 0 0;
		color: #fff;
		font-size: 18px;
		background: {
			image: url('../img/icons/cloud-icon.svg');
			size: contain;
			position: center;
			repeat: no-repeat; }
		sub {
			margin-bottom: -0.5em; } }
	&__ejections {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		&-icons {
			flex-shrink: 0;
			width: 73px;
			height: 101px;
			margin-right: 15px;
			background: {
				image: url('../img/icons/ejections-icons.svg');
				size: contain;
				position: center;
				repeat: no-repeat; } }
		&-text {
			width: 340px;
			margin: 0;
			line-height: 22px;
			font-size: 14px; } }
	&__plus {
		width: 15px;
		height: 15px;
		margin: 0 7px;
		background: {
			image: url('../img/icons/plus.svg');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__emissions {
		width: 275px;
		margin-left: 80px;
		.popup__list-heading {
			font-size: 20px; } } }

@media only screen and (max-width: 1020px) {
	.car {
		&__graph {
			padding: 35px 0 25px 0; }
		&__wrapper {
			flex-flow: column nowrap;
			width: 320px;
			margin: 0 auto;
			padding: 0 15px; }
		&__col {
			width: 100%; }
		&__text {
			margin: 0 0 15px 0 !important;
			padding: 0;
			line-height: 17px;
			font-size: 11px; }
		&__subheading {
			width: 100%;
			margin-bottom: 5px;
			line-height: 19px;
			font-size: 14px; }
		&__chart {
			margin-bottom: 15px;
			svg {
				width: 100%;
				height: auto; } }
		&__park {
			line-height: 15px;
			font-size: 11px; }
		// &__stat
		&__icon {
			width: 28px;
			height: 28px;
			margin: 0 4px;
			&:nth-child(17),
			&:nth-child(18),
			&:nth-child(19),
			&:nth-child(20) {
				display: none; } }
		&__ratio {
			margin-bottom: 18px;
			&-text {
				margin-right: 20px;
				padding: 15px;
				line-height: 17px;
				font-size: 11px; }
			&-clouds {
				flex-flow: column nowrap; } }
		&__cloud {
			width: 56px;
			height: 36px;
			padding-top: 6px;
			line-height: 19px;
			font-size: 12px; }
		&__plus {
			margin: 7px 0; }
		&__ejections {
			margin-bottom: 15px;
			&-icons {
				width: 58px;
				height: 88px;
				margin-right: 20px; }
			&-text {
				line-height: 17px;
				font-size: 11px; } }
		&__emissions {
			margin: 0 auto; } } }
