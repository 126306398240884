.about {
	z-index: 2;
	position: relative;
	margin-bottom: 55px;
	padding-top: 145px;
	&__blot {
		position: absolute;
		z-index: 1;
		top: -33px;
		left: 0px;
		width: 400px;
		height: 680px;
		background: {
			image: url('../img/blot-1.svg');
			size: contain;
			position: center;
			repeat: no-repeat; } }
	&__inner {
		position: relative;
		z-index: 2;
		max-width: 1340px;
		width: 100%;
		padding: 0; }
	&__wrap {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: flex-start;
		max-width: 1190px;
		padding-right: 40px; }
	&__image {
		width: 500px;
		margin-top: 70px; }
	&__content {
		margin-left: 35px; }
	&__text {
		margin: 0 0 10px 0;
		line-height: 26px;
		color: $new-blue;
		font-size: 17px; }
	&__heading {
		margin: 20px 0 15px 0;
		color: $new-blue;
		font-size: 17px; }
	&__list {
		margin: 0;
		padding: 0;
		&-item {
			position: relative;
			display: block;
			margin-bottom: 15px;
			padding-left: 30px;
			line-height: 1.4em;
			font-size: 17px;
			color: $new-blue;
			a {
				color: $new-blue; }
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 20px;
				height: 20px;
				background: {
					image: url('../img/icons/check.svg');
					size: contain;
					position: center;
					repeat: no-repeat; } }
			&--calc {
				cursor: pointer;
				text-decoration: underline;
				text-decoration-skip-ink: none; } } } }

@media only screen and (max-width: 1020px) {
	.about {
		margin-bottom: 15px;
		padding-top: 55px;
		&__blot {
			display: none; }
		&__image {
			display: none; }
		&__wrap {
			max-width: none;
			padding: 0; }
		&__content {
			margin-left: 0; }
		&__text {
			line-height: 16px;
			font-size: 11px; }
		&__heading {
			margin: 15px 0 10px 0;
			font-size: 11px; }
		&__list {
			&-item {
				margin-bottom: 10px;
				padding-left: 20px;
				line-height: 16px;
				font-size: 11px;
				&:before {
					top: 2px;
					width: 12px;
					height: 12px; } } } } }
